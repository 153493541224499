import React, { useContext } from 'react';
import { setupI18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Firebase, { FirebaseContext } from '../Firebase';
import { RouteComponentProps } from 'react-router';
import { History } from 'history';
import Page from '../Page';
import FormItem from '../Form/FormItem';
import { validatePassword, validateEmail } from '../Form/Validators';

const i18n = setupI18n();

interface InitialForm {
  email: string;
  password: string;
  error: string;
}

interface SignInFormProps {
  firebase: Firebase;
  history: History;
}

class SignInForm extends React.Component<SignInFormProps, InitialForm> {
  private unsubscribe?: firebase.Unsubscribe;
  constructor(props: SignInFormProps) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onLogin = this.onLogin.bind(this);
  }

  public componentDidMount() {
    this.unsubscribe = this.props.firebase.auth.onAuthStateChanged(
      this.onLogin
    );
  }

  public componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  public onLogin = (user: firebase.User | null) => {
    if (this.props.firebase.auth.currentUser) {
      if (this.unsubscribe) {
        this.unsubscribe();
      }

      this.props.history.push('/');
    }
  };

  public handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.firebase
      .doSignInWithEmailAndPassword(this.state.email, this.state.password)
      .catch(error => {
        if (error.code === 'auth/invalid-email') {
          this.setState({ error: 'Invalid email address.' });
        } else if (error.code === 'auth/user-not-found') {
          this.setState({ error: 'No user account found for that email.' });
        } else if (error.code === 'auth/wrong-password') {
          this.setState({
            error: 'Incorrect password, please try again.',
            password: ''
          });
        } else {
          this.setState({ error });
        }
      });
  };

  public handleError = (error: string) => {
    this.setState({ error });
  };

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [event.target.name as keyof InitialForm]: event.target.value,
      error: ''
    } as Pick<InitialForm, keyof InitialForm>);
  };

  public handleReset = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (validateEmail(this.state.email) === 'OK') {
      this.props.firebase.auth
        .sendPasswordResetEmail(this.state.email)
        .then(() => {
          alert('Password reset email sent.');
        })
        .catch(err => {
          console.log(err);
          alert('Reset failed, please double-check your email address!');
        });
      this.setState({
        error: ''
      });
    } else {
      this.setState({
        error: 'Please enter your email'
      });
    }
  };

  public render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        style={{ width: '75%', margin: 'auto' }}
      >
        <FormItem
          fieldId='emailField'
          value={this.state.email}
          name='email'
          type='email'
          label={i18n._(t`Email Address`)}
          helper={i18n._(t`Your registered email address`)}
          update={this.handleChange}
          handleError={this.handleError}
          validate={validateEmail}
        />
        <FormItem
          fieldId='passwordField'
          value={this.state.password}
          name='password'
          type='password'
          label={i18n._(t`Password`)}
          helper=''
          update={this.handleChange}
          handleError={this.handleError}
          validate={validatePassword}
        />

        {this.state.error.length > 0 ? (
          <p style={{ color: 'red', textAlign: 'center' }}>
            {this.state.error}
          </p>
        ) : null}

        <FormControl fullWidth style={{ marginTop: '15px' }}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            style={{ margin: 'auto' }}
            disabled={this.state.error.length > 0}
          >
            <Trans>Sign In</Trans>
          </Button>
        </FormControl>

        <FormControl fullWidth style={{ marginTop: '15px' }}>
          <Button
            variant='contained'
            color='secondary'
            style={{ margin: 'auto' }}
            onClick={this.handleReset}
          >
            <Trans>Reset Password</Trans>
          </Button>
        </FormControl>
      </form>
    );
  }
}

const SignInPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const fb = useContext(FirebaseContext);
  return (
    <Page title={i18n._(t`Sign In`)}>
      <SignInForm firebase={fb} history={props.history} />
    </Page>
  );
};

export default SignInPage;
