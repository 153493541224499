import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { RouteComponentProps } from 'react-router';
import SaveIcon from '@material-ui/icons/Save';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Page from '../Page';
import Firebase, { FirebaseContext } from '../Firebase';
import { FullUserData } from '../../Redux/Store/User';
import {
  FullProject,
  ConstructionTypes,
  ConstructionType,
  DefaultFullProject,
  ProjectStatuses,
  SupportedLang,
  ProjCard,
  ProjectStatus,
  CardType,
  DefaultYoutubeCard,
  DefaultRoomCard,
  DefaultIframeCard,
  SupportedUserLang,
  ProjectLangs,
  PhotoAlbum
} from '../../Redux/Store/Project';
import { AppState } from '../../Redux/Reducer/RootReducer';
import { AddProject, UpdateProject } from '../../Redux/Action/ProjectActions';
import Grid from '@material-ui/core/Grid';
import TradeAutocomplete from '../Trades/TradeAutocomplete';
import { Trade } from '../Trades/NewTradeList';
import LangColumn from './LangColumn';
import './Project.css';
import CardForm from './CardForm';
import PhotoUploader from '../Form/PhotoUploader';
import { extname } from 'path';

interface ProjectFormProps {
  firebase: Firebase;
  history: History;
  project: FullProject;
  admin: boolean;
  updateProject: (project: FullProject) => void;
}

interface ProjectFormState extends FullProject {
  currentLang: SupportedUserLang;
  uploading: number;
  uploaded: boolean;
}

class ProjectForm extends React.Component<ProjectFormProps, ProjectFormState> {
  constructor(props: ProjectFormProps) {
    super(props);
    this.state = {
      ...DefaultFullProject,
      ...props.project,
      currentLang: ['en', 'es'],
      uploading: 0,
      uploaded: true
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
  }

  public shouldComponentUpdate(
    nextProps: ProjectFormProps,
    nextState: FullProject
  ) {
    if (nextState.projects !== this.state.projects) return false;
    console.log(this.props);
    console.log(this.state);
    console.log(nextProps);
    console.log(nextState);
    return true;
  }

  private updateProjects = (projects: ProjectLangs) => {
    this.setState({
      projects
    });
  };

  public handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const project = this.state;

    if (project.public) {
      // add a project validator function
    }

    if (this.props.firebase.auth.currentUser) {
      // save by id
      this.props.firebase
        .updateProject(project)
        .then(() => {
          this.props.updateProject(project);
          this.props.history.push('/project/' + project.id);
        })
        .catch(err => {
          console.log(err);
        });
      /** create new project
  *       this.props.firebase
          .createProject(project)
          .then(() => {
            Mixpanel.track('Save new project', { id: project.id });
            this.props.updateProject(project);
            this.props.history.push('/project/' + project.id);
          })
          .catch(err => {
            console.log(err);
            this.setState({ error: err });
          }); */
    } else {
      console.log('No user.');
    }
  };

  private handleCheckChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (name === 'awarded' || name === 'approved' || name === 'public')
      this.setState({
        [name]: event.target.checked
      } as Pick<FullProject, 'awarded' | 'approved' | 'public'>);
  };

  private handleSelectUpdate = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    if (event.target.name === 'projectStatus') {
      this.setState({
        status: event.target.value as ProjectStatus
      });
    } else if (event.target.name === 'projectType') {
      this.setState({
        projectType: event.target.value as ConstructionType
      });
    } else if (event.target.name === 'currentLang') {
      if (event.target.value === 'bi') {
        this.setState({
          currentLang: ['en', 'es']
        });
      } else {
        this.setState({
          currentLang: event.target.value as SupportedUserLang
        });
      }
    }
  };

  private updateTrades = (trades: Trade[]) => {
    this.setState({
      trades
    });
  };

  private updatePhotos = (photos: PhotoAlbum) => {
    this.setState(state => ({
      ...state,
      photos: {
        ...state.photos,
        ...photos
      }
    }));
  };

  public render() {
    const startURL =
      'https://storage.googleapis.com/manybuildprojects.appspot.com/' +
      this.state.id +
      '/';

    const photoURLS = Object.keys(this.state.photos).map(k => ({
      hash: k,
      original: startURL + this.state.photos[k],
      processed: startURL + k + extname(this.state.photos[k])
    }));

    return (
      <form onSubmit={this.handleSubmit}>
        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            width: '200px',
            zIndex: 100,
            textAlign: 'right'
          }}
        >
          <Button
            variant='contained'
            style={{
              margin: '2.5px auto',
              lineHeight: '24px',
              fontSize: '1rem',
              backgroundColor: '#fbb200'
            }}
            startIcon={<CloudUploadIcon />}
            onClick={() => {
              const ref = document.getElementById(
                'contained-button-file-project-upload'
              );
              if (ref) ref.click();
            }}
          >
            Upload Photos
          </Button>
          <Button
            variant='contained'
            style={{
              margin: '2.5px auto',
              lineHeight: '24px',
              fontSize: '1rem',
              backgroundColor: '#fbb200'
            }}
            startIcon={<SaveIcon />}
            type='submit'
          >
            Save Project
          </Button>
        </div>
        <Grid container spacing={3} direction='row' justify='space-evenly'>
          <Grid item xs={12} md={6} lg={3}>
            <div className='newProjectFormType'>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor='projectTypeField'>
                  Project Type
                </InputLabel>
                <Select
                  value={this.state.projectType}
                  onChange={this.handleSelectUpdate}
                  input={<Input name='projectType' id='projectTypeField' />}
                  name='projectType'
                >
                  {ConstructionTypes.map(ctype => (
                    <MenuItem key={ctype} value={ctype}>
                      {ctype}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginTop: '15px' }}>
                <InputLabel shrink htmlFor='projectStatusField'>
                  Project Status
                </InputLabel>
                <Select
                  value={this.state.status}
                  onChange={this.handleSelectUpdate}
                  input={<Input name='projectStatus' id='projectStatusField' />}
                  name='projectStatus'
                >
                  {ProjectStatuses.map(status => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginTop: '15px' }}>
                <InputLabel shrink htmlFor='currentLangField'>
                  Current Language
                </InputLabel>
                <Select
                  value={
                    Array.isArray(this.state.currentLang)
                      ? 'bi'
                      : this.state.currentLang
                  }
                  onChange={this.handleSelectUpdate}
                  input={<Input name='currentLang' id='currentLangField' />}
                  name='currentLang'
                >
                  <MenuItem value='en'>English</MenuItem>
                  <MenuItem value='es'>Espanol</MenuItem>
                  <MenuItem value='bi'>Bilingual (English & Spanish)</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.public}
                    onChange={this.handleCheckChange('public')}
                    color='primary'
                    inputProps={{
                      'aria-label': 'Public checkbox'
                    }}
                  />
                }
                label='Make Public (Must be complete)'
                style={{
                  marginLeft: '15px',
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.approved}
                    onChange={this.handleCheckChange('approved')}
                    color='primary'
                    inputProps={{
                      'aria-label': 'Approved checkbox'
                    }}
                    readOnly={!this.props.admin}
                  />
                }
                label='Approve Project'
                style={{
                  marginLeft: '15px',
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
              />
              <TradeAutocomplete
                updateTrades={this.updateTrades}
                trades={this.state.trades}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <div className='newProjectFormType'>
              <CardForm
                lang='en'
                card={this.state.contact}
                key='contactCardForm'
                projectId={this.state.id}
                deleteCard={() => {}}
                updateCard={() => {}}
                handleSelectCardType={() => {}}
                moveCard={() => {}}
                handleError={error => {}}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <div className='newProjectFormType'>
              <PhotoUploader
                projectId={this.state.id}
                photos={this.state.photos}
                updatePhotos={this.updatePhotos}
              />
            </div>
          </Grid>
        </Grid>
        <LangColumn
          projects={this.state.projects}
          currentLang={this.state.currentLang}
          id={this.state.id}
          updateProjects={this.updateProjects}
        />
      </form>
    );
  }
}

interface EditPageProps extends RouteComponentProps<{ projectId: string }> {
  user: FullUserData;
  projects: FullProject[];
  updateProject: (project: FullProject) => void;
}

const mapStateToEditProps = (state: AppState) => ({
  user: state.Auth.user,
  projects: state.Project.userProjects
});
const mapDispatchToEditProps = (dispatch: any) => ({
  updateProject: (project: FullProject) => dispatch(UpdateProject(project))
});
const EditProjectPage: React.FC<EditPageProps> = props => {
  const fb = useContext(FirebaseContext);
  const projectId = props.match.params.projectId;
  const proj = props.projects.filter(proj => proj.id === projectId)[0];
  return (
    <Page title='Edit Project'>
      <ProjectForm
        firebase={fb}
        history={props.history}
        admin={props.user.admin}
        project={proj}
        updateProject={props.updateProject}
      />
    </Page>
  );
};

interface NewProjectPageProps extends RouteComponentProps {
  user: FullUserData;
  addProject: (project: FullProject) => void;
}

const mapStateToNewProps = (state: AppState) => ({
  user: state.Auth.user
});
const mapDispatchToNewProps = (dispatch: any) => ({
  addProject: (project: FullProject) => dispatch(AddProject(project))
});
const NewProjectPageBase: React.FC<NewProjectPageProps> = props => {
  const fb = useContext(FirebaseContext);
  const proj = DefaultFullProject;
  return (
    <Page title='New Project'>
      <ProjectForm
        firebase={fb}
        history={props.history}
        admin={props.user.admin}
        updateProject={props.addProject}
        project={proj}
      />
    </Page>
  );
};

const NewProjectPage = connect(
  mapStateToNewProps,
  mapDispatchToNewProps
)(NewProjectPageBase);

export default connect(
  mapStateToEditProps,
  mapDispatchToEditProps
)(EditProjectPage);
export { NewProjectPage };

/** get project by id
 *  this.props.firebase.firestore
        .collection('projects')
        .doc(this.props.id)
        .get()
        .then(doc => {
          const document = doc.data();
          if (document) {
            this.setState({
              project: {
                ...DefaultFullProject,
                ...(document as FullProject),
                approved: false
              },
              loading: false
            });
          }
        })
        .catch(err => console.log(err));
 */
