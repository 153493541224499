import React from 'react';
import { Trans } from '@lingui/macro';
import Firebase from '../Firebase';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import { FullUserData } from '../../Redux/Store/User';

const UserAdmin = (
  user: FullUserData,
  markPaid: (id: string, paid: boolean) => void,
  updateUserEdit: (val: string) => void
) => (
  <TableRow key={user.id}>
    <TableCell component='th' scope='row'>
      {user.firstName + ' ' + user.lastName + '\t'}{' '}
      <IconButton
        aria-label='Edit User'
        type='button'
        color='primary'
        onClick={event => {
          updateUserEdit(user.id);
        }}
      >
        <EditIcon />
      </IconButton>
    </TableCell>
    <TableCell align='center'>{user.companyName}</TableCell>
    <TableCell align='center'>
      {user.paid ? <Trans>Paid</Trans> : <Trans>Unpaid</Trans>}
    </TableCell>
    <TableCell align='center'>
      <IconButton
        aria-label='Paid'
        type='button'
        color='primary'
        style={{ margin: 'auto', display: 'block' }}
        onClick={event => {
          markPaid(user.id, user.paid);
        }}
      >
        <CheckIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);

interface ProjectPanelProps {
  firebase: Firebase;
  updateUserEdit: (val: string) => void;
}

interface ProjectPanelState {
  users: FullUserData[];
}

export default class ProjectPanel extends React.Component<
  ProjectPanelProps,
  ProjectPanelState
> {
  constructor(props: ProjectPanelProps) {
    super(props);
    this.state = {
      users: []
    };
    this.markPaid = this.markPaid.bind(this);
  }

  public componentDidMount() {
    this.props.firebase.firestore
      .collection('users')
      .get()
      .then(docsnap => {
        const users: FullUserData[] = [];
        docsnap.forEach(doc => {
          users.push({ ...(doc.data() as FullUserData), id: doc.id });
        });
        this.setState({
          users
        });
      })
      .catch(err => console.log(err));
  }

  private markPaid = (id: string, paid: boolean) => {
    this.props.firebase.firestore
      .collection('users')
      .doc(id)
      .update({ paid: !paid })
      .then(() => {
        this.setState({
          users: this.state.users.map(user =>
            user.id === id ? { ...user, paid: !paid } : user
          )
        });
      })
      .catch(err => console.log(err));
  };

  public render() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Trans>User</Trans>
            </TableCell>
            <TableCell align='center'>
              <Trans>Company</Trans>
            </TableCell>
            <TableCell align='center'>
              <Trans>Paid</Trans>
            </TableCell>
            <TableCell align='center'>
              <Trans>Mark as Paid</Trans>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.users.map(user =>
            UserAdmin(user, this.markPaid, this.props.updateUserEdit)
          )}
        </TableBody>
      </Table>
    );
  }
}
