import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nProvider } from '@lingui/react';
import Routes from '../Routes';
import en from '../../locales/en/messages';
import es from '../../locales/es/messages';
import './App.css';
import { UserState } from '../../Redux/Store/User';
import { connect } from 'react-redux';
import { AppState } from '../../Redux/Reducer/RootReducer';

type AppProps = UserState;

const App: React.FC<AppProps> = (props: AppProps) => (
  <div id='app_container'>
    <I18nProvider language='en' catalogs={{ en, es }}>
      <BrowserRouter>
        <Routes isAuthenticated={props.signedIn} isAdmin={props.user.admin} />
      </BrowserRouter>
    </I18nProvider>
  </div>
);

const mapStateToProps = (state: AppState) => ({
  user: state.Auth.user,
  signedIn: state.Auth.signedIn
});

export default (connect(mapStateToProps, null)(App) as unknown) as React.FC;
