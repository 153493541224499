import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import Mixpanel from '../Mixpanel';
import { FullUserData, UserData } from '../../Redux/Store/User';
import { FullBid } from '../../Redux/Store/Bid';
import { FullProject } from '../../Redux/Store/Project';
import { FirebaseProps } from './FirebaseContext';

const firebaseConfig = {
  apiKey: 'AIzaSyD-D0YidHevN52A4Thcelh3KGbv2IX80Dw',
  authDomain: 'manybuildprojects.firebaseapp.com',
  databaseURL: 'https://manybuildprojects.firebaseio.com',
  projectId: 'manybuildprojects',
  storageBucket: 'manybuildprojects.appspot.com',
  messagingSenderId: '1037860274802',
  appId: '1:1037860274802:web:f5417a8a769a0e13'
};

class Firebase {
  public auth: firebase.auth.Auth;
  public firestore: firebase.firestore.Firestore;
  public storage: firebase.storage.Storage;
  public functions: firebase.functions.Functions;
  public authStateReported: boolean;
  public reduxActions: FirebaseProps;

  constructor(props: FirebaseProps) {
    firebase.initializeApp(firebaseConfig);
    this.auth = firebase.auth();
    this.firestore = firebase.firestore();
    this.storage = firebase.storage();
    this.functions = firebase.functions();

    this.reduxActions = props;
    this.authStateReported = false;
    this.getUser = this.getUser.bind(this);
    this.getByQuery = this.getByQuery.bind(this);
    this.authStateListener = this.authStateListener.bind(this);
    this.auth.onAuthStateChanged(this.authStateListener);
  }

  private doLocal() {}

  public authStateListener(user: firebase.User | null) {
    this.authStateReported = true;
    if (user) {
      const { uid } = user;
      Mixpanel.init();
      Mixpanel.identify(uid);

      if (user.metadata.creationTime === user.metadata.lastSignInTime) {
        Mixpanel.track('New user', { id: uid });
      } else {
        this.getUser(uid)
          .then(doc => {
            if (doc) {
              this.reduxActions.addUser(doc.data() as FullUserData);
              this.getByQuery('projects', 'ownerId', '==', uid)
                .then(data =>
                  this.reduxActions.loadUserProjects(data as FullProject[])
                )
                .catch(err => console.log(err));
              this.getByQuery('bids', 'ownerId', '==', uid)
                .then(data => this.reduxActions.loadBids(data as FullBid[]))
                .catch(err => console.log(err));
            }
          })
          .catch(err => console.log(err));
      }
    } else {
      this.reduxActions.clearUser();
      this.reduxActions.clearProjects();
      this.reduxActions.clearBids();
    }
  }

  public doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  public doSignInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  public doSignOut = () => this.auth.signOut();

  public doPasswordReset = (email: string) =>
    this.auth.sendPasswordResetEmail(email);

  public doPasswordUpdate = (password: string) =>
    this.auth.currentUser
      ? this.auth.currentUser.updatePassword(password)
      : new Promise<string>((res, rej) => rej('No signed-in user.'));

  public addUser = (user: UserData, uid: string) =>
    this.firestore
      .collection('users')
      .doc(uid)
      .set({ ...user, id: uid });

  public updateUser = (user: FullUserData) =>
    this.firestore
      .collection('users')
      .doc(user.id)
      .update(user);

  public getUser = (uid: string) =>
    this.firestore
      .collection('users')
      .doc(uid)
      .get();

  public createBid = (bid: FullBid) =>
    this.firestore
      .collection('bids')
      .doc(bid.id)
      .set(bid);

  public updateBid = (bid: FullBid) =>
    this.firestore
      .collection('bids')
      .doc(bid.id)
      .update(bid);

  public deleteBid = (id: string) =>
    this.firestore
      .collection('bids')
      .doc(id)
      .delete();

  public createProject = (project: FullProject) =>
    this.firestore
      .collection('projects')
      .doc(project.id)
      .set(project);

  public updateProject = (project: FullProject) =>
    this.firestore
      .collection('projects')
      .doc(project.id)
      .update(project);

  public deleteProject = (id: string) =>
    this.firestore
      .collection('projects')
      .doc(id)
      .delete();

  public getByQuery = (
    collection: string,
    fieldQuery: string,
    opStr: firebase.firestore.WhereFilterOp,
    val: any
  ) =>
    this.firestore
      .collection(collection)
      .where(fieldQuery, opStr, val)
      .get()
      .then(snap => snap.docs.map(doc => doc.data()));
}

export default Firebase;
