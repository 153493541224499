import {
  FullUserData,
  UserActionTypes,
  UPDATE_USER,
  CLEAR_USER,
  ADD_USER
} from '../Store/User';

export function AddUser(user: FullUserData): UserActionTypes {
  return {
    type: ADD_USER,
    user
  };
}

export function UpdateUser(user: FullUserData): UserActionTypes {
  return {
    type: UPDATE_USER,
    user
  };
}

export function ClearUser(): UserActionTypes {
  return {
    type: CLEAR_USER
  };
}
