import React from 'react';
import Firebase from '../Firebase';
import { Dialog, DialogTitle, IconButton, DialogContent } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { Trans } from '@lingui/react';
import FormItem from '../Form/FormItem';
import { validateNonEmpty } from '../Form/Validators';

interface AwardModalProps {
    firebase: Firebase;
    projectId: string;
    initValue: number;
    handleAward: (id: string, awardValue: number) => void;
    closeModal: () => void;
}

const AwardModal: React.FC<AwardModalProps> = (props: AwardModalProps) => {
    const [ awardValue, updateAwardValue ] = React.useState(props.initValue);

    const updateField = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateAwardValue(Number(e.target.value));
    }

    return (
                 <Dialog
          open={props.projectId.length > 0}
          aria-labelledby='Award edit form'
          aria-describedby='award-edit-form-description'
          onClose={props.closeModal}
        >
          <DialogTitle>
            <Trans>Award Project</Trans>
            <IconButton
              aria-label='Close'
              type='button'
              color='secondary'
              style={{ position: 'absolute', right: '2.5%', top: '1%' }}
              onClick={props.closeModal}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
              <form>
                  <FormItem name="awardValue" type="number" value={awardValue.toString()} update={updateField}
                  fieldId='awardValueField'
                  label='Award Value'
                  helper='Set to 0 to remove award from project'
                  validate={validateNonEmpty("Award Value")}
                  handleError={(error) => {}}/>
              </form>
          </DialogContent>
        </Dialog>
    )
}

export default AwardModal;