import {
  UserActionTypes,
  UPDATE_USER,
  CLEAR_USER,
  UserState,
  DefaultUser,
  ADD_USER
} from '../Store/User';

const initialState: UserState = {
  user: DefaultUser,
  signedIn: false
};

export function userReducer(
  state = initialState,
  action: UserActionTypes
): UserState {
  switch (action.type) {
    case ADD_USER:
      return {
        user: action.user,
        signedIn: true
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.user
      };
    case CLEAR_USER:
      return initialState;
    default:
      return state;
  }
}
