import {
  FullProject,
  ProjectActionTypes,
  LOAD_USER_PROJECTS,
  ADD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  DisplayProject,
  LOAD_MARKET_PROJECTS,
  ConstructionType,
  UPDATE_MARKET_TYPE,
  CLEAR_USER_PROJECTS,
  UPDATE_LANGUAGE,
  SupportedLang
} from '../Store/Project';

export function LoadUserProjects(
  projects: FullProject[]
): ProjectActionTypes {
  return {
    type: LOAD_USER_PROJECTS,
    projects
  };
}

export function ClearUserProjects(): ProjectActionTypes {
  return {
    type: CLEAR_USER_PROJECTS
  };
}

export function AddProject(project: FullProject): ProjectActionTypes {
  return {
    type: ADD_PROJECT,
    project
  };
}

export function UpdateProject(project: FullProject): ProjectActionTypes {
  return {
    type: UPDATE_PROJECT,
    project
  };
}

export function DeleteProject(id: string): ProjectActionTypes {
  return {
    type: DELETE_PROJECT,
    id
  };
}

export function LoadMarketProjects(
  projects: DisplayProject[]
): ProjectActionTypes {
  return {
    type: LOAD_MARKET_PROJECTS,
    projects
  };
}

export function UpdateMarketType(
  marketType: ConstructionType[],
  projects: DisplayProject[]
): ProjectActionTypes {
  return {
    type: UPDATE_MARKET_TYPE,
    marketType,
    projects
  };
}

export function UpdateLanguage(lang: SupportedLang): ProjectActionTypes {
  return {
    type: UPDATE_LANGUAGE,
    lang
  };
}
