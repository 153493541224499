import React from 'react';
import logoWhite from '../../assets/ManyBuildWhite.png';

class Footer extends React.Component {
  public render() {
    return (
      <footer id='footer'>
        <img src={logoWhite} alt='Manybuild' />
        <p>alex@manybuildsolutions.com</p>
        <p>(720) 334-8842</p>
      </footer>
    );
  }
}

export default Footer;
