import { t } from '@lingui/macro';
import { setupI18n } from '@lingui/core';

const i18n = setupI18n();

export const validateEmail = (email: string) => {
  if (
    email.length < 1 ||
    !email.match(
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    return i18n._(t`Please enter an email.`);
  }
  return i18n._(t`OK`);
};

export const validatePassword = (password: string) => {
  if (password.length < 1) return i18n._(t`Please enter your password.`);
  return i18n._(t`OK`);
};

export const validateNewPassword = (password: string) => {
  if (password.length < 6) {
    return i18n._(t`At least 6 characters`);
  }
  return i18n._(t`OK`);
};

export const validateNonEmpty = (name: string) => (val: string) => {
  if (val.length < 1) {
    return i18n._(t`Please enter a`) + ' ' + name;
  }
  return i18n._(t`OK`);
};

export const validatePhoneNum = (num: string) => {
  if (num.length !== 10 || !num.match(/([0-9]+)/)) {
    return i18n._(t`Please enter phone number with area code`);
  }
  return i18n._(t`OK`);
};

export const validateNonReq = (val: string) => i18n._(t`OK`);
