import React from 'react';
import { setupI18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import FormItem from '../Form/FormItem';
import Firebase from '../Firebase';
import {
  validateNonEmpty,
  validateEmail,
  validatePhoneNum,
  validateNonReq
} from '../Form/Validators';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { FullUserData, DefaultUser } from '../../Redux/Store/User';
import { UpdateUser } from '../../Redux/Action/UserActions';
import { connect } from 'react-redux';
import { ConstructionTypes, ConstructionType } from '../../Redux/Store/Project';
import TradeAutocomplete from '../Trades/TradeAutocomplete';

const i18n = setupI18n();

interface EditUserFormProps {
  firebase: Firebase;
  closeModal: (id: string) => void;
  id: string;
  admin: boolean;
  user?: FullUserData;
  updateUser: (user: FullUserData) => void;
}

const mapDispatchToProps = (dispatch: any) => ({
  updateUser: (user: FullUserData) => dispatch(UpdateUser(user))
});

interface EditUserFormState {
  user: FullUserData;
  error: boolean;
  errorStr: string;
}

class EditUserForm extends React.Component<
  EditUserFormProps,
  EditUserFormState
> {
  constructor(props: EditUserFormProps) {
    super(props);

    this.state = {
      user: props.user ? props.user : { ...DefaultUser, id: this.props.id },
      error: false,
      errorStr: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  public componentDidMount() {
    if (!this.props.user && this.props.id.length > 0) {
      this.props.firebase.firestore
        .collection('users')
        .doc(this.props.id)
        .get()
        .then(snap => {
          this.setState({
            user: {
              ...this.state.user,
              ...(snap.data() as FullUserData),
              id: this.props.id
            }
          });
        })
        .catch(err => console.log(err));
    }
  }

  private handleMultipleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const value = event.target.value as ConstructionType[];

    this.setState({
      user: {
        ...this.state.user,
        preferredWork: value
      }
    });
  };

  private handleError = (error: string) => {
    this.setState({
      error: true,
      errorStr: error
    });
  };

  private handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | { name?: string | undefined; value: unknown }
    >
  ) => {
    this.setState({
      user: {
        ...this.state.user,
        [event.target.name as keyof FullUserData]: event.target.value
      }
    });
  };

  private handleCheck = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      user: {
        ...this.state.user,
        [name]: event.target.checked
      }
    });
  };

  private handleSave = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const fullName = this.state.user.firstName + ' ' + this.state.user.lastName;

    this.props.firebase
      .updateUser({ ...this.state.user, fullName })
      .then(() => {
        if (this.props.user && this.props.firebase.auth.currentUser) {
          this.props.firebase.auth.currentUser
            .updateProfile({
              displayName: fullName
            })
            .catch(err => this.setState({ error: err }));
          this.props.updateUser({ ...this.state.user, fullName });
        }

        this.props.closeModal('');
      })
      .catch(err => console.log(err));
  };

  public render() {
    return (
      <React.Fragment>
        <DialogContent>
          <form>
            <FormItem
              fieldId='einField'
              value={this.state.user.ein}
              name='ein'
              label='EIN'
              helper=''
              update={this.handleChange}
              handleError={this.handleError}
              validate={validateNonEmpty('ein')}
            />
            <FormItem
              fieldId='firstNameField'
              value={this.state.user.firstName}
              name='firstName'
              label={i18n._(t`First Name`)}
              helper=''
              update={this.handleChange}
              handleError={this.handleError}
              validate={validateNonEmpty('first name')}
            />
            <FormItem
              fieldId='lastNameField'
              value={this.state.user.lastName}
              name='lastName'
              label={i18n._(t`Last Name`)}
              helper=''
              update={this.handleChange}
              handleError={this.handleError}
              validate={validateNonEmpty('last name')}
            />
            <FormItem
              fieldId='middleNameField'
              value={this.state.user.middleName}
              name='middleName'
              label={i18n._(t`Middle Name`)}
              helper=''
              update={this.handleChange}
              handleError={this.handleError}
              validate={validateNonReq}
            />
            <FormItem
              fieldId='emailField'
              value={this.state.user.email}
              name='email'
              label={i18n._(t`Email`)}
              helper=''
              type='email'
              update={this.handleChange}
              handleError={this.handleError}
              validate={validateEmail}
            />
            <FormItem
              fieldId='phoneNumField'
              value={this.state.user.phoneNum}
              name='phoneNum'
              label={i18n._(t`Phone Number`)}
              helper='Main phone'
              update={this.handleChange}
              handleError={this.handleError}
              validate={validatePhoneNum}
            />
            {this.props.admin ? (
              <React.Fragment>
                <FormItem
                  fieldId='accountOwnerField'
                  value={this.state.user.accountOwner}
                  name='accountOwner'
                  label={i18n._(t`Account Owner`)}
                  helper=''
                  update={this.handleChange}
                  handleError={this.handleError}
                  validate={validateNonReq}
                />
                <FormItem
                  fieldId='leadAcqTime'
                  value={this.state.user.leadAcqTime}
                  name='leadAcqTime'
                  label={i18n._(t`Lead Acquire Time (FB)`)}
                  helper=''
                  update={this.handleChange}
                  handleError={this.handleError}
                  validate={validateNonReq}
                />
                <FormItem
                  fieldId='notesField'
                  value={this.state.user.notes}
                  name='notes'
                  label={i18n._(t`Notes`)}
                  helper=''
                  update={this.handleChange}
                  handleError={this.handleError}
                  validate={validateNonReq}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.user.admin}
                      onChange={this.handleCheck('admin')}
                      value='Admin'
                      color='primary'
                    />
                  }
                  label={i18n._(t`Notes`)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.user.paid}
                      onChange={this.handleCheck('paid')}
                      value='Paid'
                      color='primary'
                    />
                  }
                  label={i18n._(t`Paid`)}
                />
              </React.Fragment>
            ) : null}
            <FormControl fullWidth style={{ marginTop: '15px' }}>
              <InputLabel htmlFor='lang'>
                <Trans>Language</Trans>
              </InputLabel>
              <Select
                value={this.state.user.language}
                onChange={this.handleChange}
                name='language'
                inputProps={{ id: 'lang', name: 'language' }}
              >
                <MenuItem value={'esp'}>Español</MenuItem>
                <MenuItem value={'eng'}>English</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.user.bilingual}
                  onChange={this.handleCheck('bilingual')}
                  value='Bilingual'
                  color='primary'
                />
              }
              label={i18n._(t`Bilingual`)}
            />
            <FormControl fullWidth>
              <InputLabel shrink htmlFor='preferredWorkField'>
                <Trans>Preferred Work</Trans>
              </InputLabel>
              <Select
                value={
                  this.state.user.preferredWork
                    ? this.state.user.preferredWork
                    : []
                }
                onChange={this.handleMultipleChange}
                input={<Input name='preferredWork' id='preferredWorkField' />}
                renderValue={selected => (selected as string[]).join(', ')}
                multiple
                name='preferredWork'
              >
                {ConstructionTypes.map(ctype => (
                  <MenuItem key={ctype} value={ctype}>
                    {ctype}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
              <TradeAutocomplete
                categories
                trades={this.state.user.trades.map(trade => ({
                  trade: '',
                  category: trade,
                  sortOrder: 0
                }))}
                updateTrades={trades =>
                  this.setState({
                    user: {
                      ...this.state.user,
                      trades: trades.map(trade => trade.category)
                    }
                  })
                }
              />
            </div>

            <FormItem
              fieldId='mobileNumField'
              value={this.state.user.mobileNum}
              name='mobileNum'
              label={i18n._(t`Mobile Number`)}
              helper=''
              update={this.handleChange}
              handleError={this.handleError}
              validate={validatePhoneNum}
            />
            <FormItem
              fieldId='phoneNumTwoField'
              value={this.state.user.phoneNumTwo}
              name='phoneNumTwo'
              label={i18n._(t`Second Phone Number`)}
              helper=''
              update={this.handleChange}
              handleError={this.handleError}
              validate={validatePhoneNum}
            />
            <FormItem
              fieldId='faxNumField'
              value={this.state.user.faxNum}
              name='faxNum'
              label={i18n._(t`Fax Number`)}
              helper=''
              update={this.handleChange}
              handleError={this.handleError}
              validate={validatePhoneNum}
            />
            <FormItem
              fieldId='companyNameField'
              value={this.state.user.companyName}
              name='companyName'
              label={i18n._(t`Company Name`)}
              helper=''
              update={this.handleChange}
              handleError={this.handleError}
              validate={validateNonEmpty('company name')}
            />
            <FormItem
              fieldId='companyWebAddrField'
              value={this.state.user.companyWebAddr}
              name='companyWebAddr'
              label={i18n._(t`Company Web Address`)}
              helper=''
              update={this.handleChange}
              handleError={this.handleError}
              validate={validateNonReq}
            />
            <FormItem
              fieldId='officeHoursField'
              value={this.state.user.officeHours}
              name='officeHours'
              label={i18n._(t`Office Hours`)}
              helper=''
              update={this.handleChange}
              handleError={this.handleError}
              validate={validateNonReq}
            />
            <FormItem
              fieldId='mailAddrField'
              value={this.state.user.mailAddr}
              name='mailAddr'
              label={i18n._(t`Mailing Address`)}
              helper=''
              update={this.handleChange}
              handleError={this.handleError}
              validate={validateNonEmpty('mailing address')}
            />
            <FormItem
              fieldId='logoField'
              value={this.state.user.logo}
              name='logo'
              label={i18n._(t`Logo`)}
              helper=''
              update={this.handleChange}
              handleError={this.handleError}
              validate={validateNonReq}
            />
            <FormItem
              fieldId='companyDocsField'
              value={this.state.user.companyDocs}
              name='companyDocs'
              label={i18n._(t`Company and Insurance Documents`)}
              helper=''
              update={this.handleChange}
              handleError={this.handleError}
              validate={validateNonReq}
            />

            <FormItem
              fieldId='insuranceExpirationField'
              value={this.state.user.insuranceExpiration}
              name='insuranceExpiration'
              label={i18n._(t`Insurance Expiration Date`)}
              helper=''
              update={this.handleChange}
              handleError={this.handleError}
              validate={validateNonReq}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={this.handleSave}>
            <Trans>Save Changes</Trans>
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default connect(null, mapDispatchToProps)(EditUserForm);
