import React, { useContext } from 'react';
import Firebase, { FirebaseContext } from '../Firebase';
import DragDropPreview from './DragAndDropPreview';
import { GetBase64, GetHash } from '../../Global';
import { PhotoUrl, PhotoAlbum } from '../../Redux/Store/Project';
import { extname, basename } from 'path';

interface UploaderState {
  photoUrls: PhotoUrl[];
  photos: string[];
  uploading: number;
  photoCapture: boolean;
  uploaded: boolean;
}

interface BaseUploaderProps {
  projectId: string;
  photos: PhotoAlbum;
  updatePhotos: (photos: PhotoAlbum) => void;
}

interface UploaderProps extends BaseUploaderProps {
  firebase: Firebase;
}

class PhotoUploaderBase extends React.Component<UploaderProps, UploaderState> {
  constructor(props: UploaderProps) {
    super(props);
    this.state = {
      photoUrls: Object.keys(props.photos).map(k => ({
        hash: k,
        original:
          'https://storage.googleapis.com/manybuildprojects.appspot.com/' +
          props.projectId +
          '/' +
          props.photos[k],
        processed:
          'https://storage.googleapis.com/manybuildprojects.appspot.com/' +
          props.projectId +
          '/' +
          k +
          extname(props.photos[k])
      })),
      uploading: 0,
      photoCapture: false,
      uploaded: true,
      photos: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  private handleFiles = (files: File[]) => {
    if (this.props.firebase.auth.currentUser) {
      this.setState({
        uploading: files.length,
        uploaded: false
      });

      Promise.all(files.map(f => GetBase64(f)))
        .then(baseArr => {
          const fileArr = baseArr.map((val, ind) => ({
            base64: val.startsWith('data')
              ? val.replace(/(^data[^,]*,)/, '')
              : val,
            type: files[ind].type,
            name: files[ind].name.slice(0, files[ind].name.indexOf('.')),
            hash: GetHash(val),
            projectId: this.props.projectId
          }));

          console.log(fileArr);

          const UploadPhoto = this.props.firebase.functions.httpsCallable(
            'UploadPhoto'
          );

          fileArr.forEach(f =>
            UploadPhoto(f)
              .then(res => {
                console.log(res);
                const startUrl =
                  'https://storage.googleapis.com/manybuildprojects.appspot.com/';

                this.props.updatePhotos({
                  [res.data.hash]: basename(res.data.original)
                });

                this.setState({
                  photoUrls: [
                    ...this.state.photoUrls,
                    {
                      original: startUrl + res.data.original,
                      processed: startUrl + res.data.processed,
                      hash: res.data.hash
                    }
                  ],
                  uploading: this.state.uploading - 1
                });
              })
              .catch(err => console.log(err))
          );
        })
        .catch(err => console.log(err));
    }
  };

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      this.handleFiles(
        Array.from(e.target.files).filter(f =>
          /\.(jpe?g|png|pdf|webp)$/i.test(f.name) ? f : null
        )
      );
    }
  };

  private handleDelete = (ind: number) => {
    if (this.state.photoUrls.length > ind) {
      const ref = this.props.firebase.storage.ref(
        this.state.photoUrls[ind].original
      );
      ref
        .getMetadata()
        .then(resp => {
          if (
            resp.customMetadata &&
            resp.customMetadata.srcPath &&
            resp.fullPath
          ) {
            const paths: string[] = [
              resp.customMetadata.srcPath,
              resp.fullPath
            ];
            const webpPath = paths[1].slice(0, paths[1].length - 3) + 'webp';
            paths.push(webpPath);

            return Promise.all(
              paths.map(p => this.props.firebase.storage.ref(p).delete())
            );
          } else {
            throw new Error('Not all referenced files found.');
          }
        })
        .then(() => {
          this.setState({
            photoUrls: this.state.photoUrls.filter((url, num) => num !== ind)
          });
        })
        .catch(err => {
          this.setState({
            photoUrls: this.state.photoUrls.filter((url, num) => num !== ind)
          });
          console.log(err);
        });
    }
  };

  public render() {
    return (
      <React.Fragment>
        <input
          accept='image/*, application/pdf'
          id='contained-button-file-project-upload'
          multiple
          type='file'
          onChange={this.handleChange}
          style={{ display: 'none' }}
        />
        {/*<FormControl fullWidth style={{ marginTop: '10px' }}>
          <input
            accept='image/*'
            capture='camera'
            id={'contained-button-capture' + this.props.id}
            multiple
            type='file'
            onChange={this.handleChange}
            style={{ display: 'none' }}
          />
          {<label
            htmlFor={'contained-button-capture' + this.props.id}
            style={{ margin: 'auto' }}
          >
            <Button
              variant='contained'
              component='span'
              style={{ margin: 'auto' }}
            >
              <Trans>Take Photos</Trans>
            </Button>
          </label>
        </FormControl>*/}
        <DragDropPreview
          photoUrls={this.state.photoUrls}
          handleUpdate={this.handleFiles}
          uploading={this.state.uploading}
          uploaded={this.state.uploaded}
          deletePic={this.handleDelete}
        />
      </React.Fragment>
    );
  }
}

const PhotoUploader: React.FC<BaseUploaderProps> = (
  props: BaseUploaderProps
) => {
  const fb = useContext(FirebaseContext);
  return <PhotoUploaderBase firebase={fb} {...props} />;
};

export default PhotoUploader;
