import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

interface FormItemProps {
  fieldId: string;
  value: string;
  label: string;
  name: string;
  helper: string;
  type?: string;
  autofocus?: boolean;
  rows?: string;
  multiline?: boolean;
  update: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleError: (error: string) => void;
  validate: (val: string) => string;
}

const FormItem: React.FC<FormItemProps> = (props: FormItemProps) => {
  const [{ helperText, err }, updateHelperText] = useState({
    helperText: props.helper,
    err: false
  });

  const inputProps = {
    step: 0.1,
    min: 0
  };

  return (
    <FormControl fullWidth style={{ marginTop: '15px' }}>
      <FormLabel htmlFor={props.fieldId}>{props.label}</FormLabel>
      <TextField
        name={props.name}
        autoFocus={props.autofocus ? true : false}
        type={props.type ? props.type : ''}
        inputProps={inputProps}
        value={props.value}
        id={props.fieldId}
        aria-describedby={props.fieldId + 'helper'}
        onChange={props.update}
        multiline={props.multiline}
        rows={props.rows ? props.rows : '1'}
        rowsMax={props.rows ? props.rows : '1'}
        style={props.multiline ? { height: '100%' } : {}}
        onBlur={() => {
          const errCheck = props.validate(props.value);
          if (errCheck !== 'OK') {
            updateHelperText({ helperText: errCheck, err: true });
            props.handleError(errCheck);
          } else {
            if (err) props.handleError('');
            updateHelperText({ helperText: props.helper, err: false });
          }
        }}
      />
      <FormHelperText id={props.fieldId + 'helper'} error={err}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default FormItem;
