import React, { useContext } from 'react';
import { Trans } from '@lingui/macro';
import Firebase, { FirebaseContext } from '../Firebase';
import { Link } from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Page from '../Page';
import { UserBidList } from '../Bid';
import { ProjectList } from '../Project';
import EditUserForm from './EditUser';
import { FullUserData, UserState } from '../../Redux/Store/User';
import { AppState } from '../../Redux/Reducer/RootReducer';
import { connect } from 'react-redux';
import { DeleteProject } from '../../Redux/Action/ProjectActions';

/**
 * UserPageProps interface
 * firebase: Firebase class
 * user: Full user data (must be logged in to get here)
 * deleteProject: Redux function for removing project from state
 */
interface UserPageProps {
  firebase: Firebase;
  user: FullUserData;
  deleteProject: (id: string) => void;
}

/**
 * UserPageState
 * open: edit user dialog open or no
 */
interface UserPageState {
  open: boolean;
}

/**
 * User base class
 */
class User extends React.Component<UserPageProps, UserPageState> {
  /**
   * constructor: sets open to false
   * @param props
   */
  constructor(props: UserPageProps) {
    super(props);
    this.state = {
      open: false
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  /**
   * handleOpen: opens up edit dialog
   */
  private handleOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({
      open: true
    });
  };

  /**
   * handleClose: closes the edit dialog
   */
  private handleClose = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({
      open: false
    });
  };

  /**
   * handleDelete: deletes project with id from props
   * confirms with user before delete
   */
  private handleDelete = (id: string) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const sure = window.confirm(
      'Are you sure you want to delete this project?'
    );
    if (sure) {
      this.props.firebase
        .deleteProject(id)
        .then(() => {
          this.props.deleteProject(id);
          alert('Project deleted!');
        })
        .catch(err => console.log(err));
    }
  };

  public render() {
    return (
      <React.Fragment>
        <IconButton
          aria-label='Edit User'
          type='button'
          color='primary'
          onClick={this.handleOpen}
          style={{
            display: 'block',
            margin: 'auto'
          }}
        >
          <EditIcon />
        </IconButton>
        {/** Change password link */}
        <h6 style={{ textAlign: 'center' }}>
          <Link to='/change-password'>
            <Trans>Change Your Password</Trans>
          </Link>
        </h6>
        <h3 style={{ textAlign: 'center' }}>
          <Trans>Projects</Trans>
        </h3>
        {/** List of user's projects */}
        <ProjectList handleDelete={this.handleDelete} />
        <h3 style={{ textAlign: 'center', marginTop: '25px' }}>
          <Trans>Bids</Trans>
        </h3>
        {/** List of user's bids */}
        <UserBidList />
        {/** Edit user dialog: initially closed */}
        <Dialog
          open={this.state.open}
          aria-labelledby='User edit form'
          aria-describedby='User-edit-form-description'
          onClose={this.handleClose}
        >
          <DialogTitle>
            <Trans>Edit User</Trans>
            <IconButton
              aria-label='Close'
              type='button'
              color='secondary'
              style={{ position: 'absolute', right: '2.5%', top: '1%' }}
              onClick={this.handleClose}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <EditUserForm
            closeModal={id => this.setState({ open: false })}
            firebase={this.props.firebase}
            id={this.props.user.id}
            admin={false}
            user={this.props.user}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

/**
 * Maps redux functions to props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({
  deleteProject: (id: string) => dispatch(DeleteProject(id))
});
/**
 * Connects redux and User
 */
const UserDisplay = connect(null, mapDispatchToProps)(User);
/**
 * Connects redux state to props
 * @param state
 */
const mapStateToProps = (state: AppState) => ({
  user: state.Auth.user
});
/**
 * UserPage functional component: gets user from redux
 * and firebase from context and passes them to userdisplay
 * @param props
 */
const UserPage: React.FC<UserState> = (props: UserState) => {
  const firebase = useContext(FirebaseContext);

  return (
    <Page title={props.user.fullName}>
      <UserDisplay firebase={firebase} user={props.user} />
    </Page>
  );
};

/**
 * Connects redux to UserPage
 */
export default connect(mapStateToProps, null)(UserPage);
