import React from 'react';
import { Link } from 'react-router-dom';
import { connect, Omit } from 'react-redux';
import { Trans } from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { FullProject, DisplayProject } from '../../Redux/Store/Project';
import { AppState } from '../../Redux/Reducer/RootReducer';

interface ProjectListProps {
  projects: FullProject[];
  handleDelete: (
    id: string
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ProjectListItem = (
  proj: DisplayProject,
  handleDelete: (
    id: string
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
) => (
  <TableRow key={proj.id}>
    <TableCell component='th' scope='row'>
      <Link to={'/project/' + proj.id}>{proj.id}</Link>
    </TableCell>
    <TableCell align='center'>{proj.cards.length}</TableCell>
    <TableCell align='center'>
      {proj.approved ? (
        <Trans>Approved</Trans>
      ) : (
        <Trans>Pending Admin Approval</Trans>
      )}
    </TableCell>
    <TableCell align='center'>
      {proj.public ? <Trans>Public</Trans> : <Trans>Private</Trans>}
    </TableCell>
    <TableCell align='center'>
      <IconButton
        aria-label='Delete'
        type='button'
        color='secondary'
        style={{ margin: 'auto', display: 'block' }}
        onClick={handleDelete(proj.id)}
      >
        <DeleteIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);
const mapStateToProps = (state: AppState) => ({
  projects: state.Project.userProjects
});
const ProjectList: React.FC<ProjectListProps> = props => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>
          <Trans>Project</Trans>
        </TableCell>
        <TableCell align='center'>
          <Trans>Rooms</Trans>
        </TableCell>
        <TableCell align='center'>
          <Trans>Status</Trans>
        </TableCell>
        <TableCell align='center'>
          <Trans>Public</Trans>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {props.projects.map(proj => ProjectListItem({ ...proj, cards: proj.projects.en }, props.handleDelete))}
    </TableBody>
  </Table>
);

export default (connect(
  mapStateToProps,
  null
)(ProjectList) as unknown) as React.ComponentType<
  Omit<ProjectListProps, 'projects'>
>;
