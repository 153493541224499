import { ConstructionType, SupportedLang } from './Project';
import { TradeListCategory } from '../../Components/Trades/NewTradeList';

export interface UserData {
  fullName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  areaCode: string;
  exchange: string;
  number: string;
  phoneNum: string;
  mobileNum: string;
  phoneNumTwo: string;
  faxNum: string;
  companyName: string;
  companyWebAddr: string;
  officeHours: string;
  mailAddr: string;
  language: SupportedLang;
  trades: TradeListCategory[];
  preferredWork: ConstructionType[];
  bilingual: boolean;
  notes: string;
  logo: string;
  companyDocs: string;
  ein: string;
  insuranceExpiration: string;
  accountOwner: string;
  leadAcqTime: string;
  projectView: number;
  projectPost: number;
  admin: boolean;
  paid: boolean;
}

export const DefaultUser: FullUserData = {
  id: '',
  fullName: '',
  firstName: '',
  lastName: '',
  middleName: '',
  email: '',
  areaCode: '',
  exchange: '',
  number: '',
  phoneNum: '',
  mobileNum: '',
  phoneNumTwo: '',
  faxNum: '',
  companyName: '',
  companyWebAddr: '',
  officeHours: '',
  mailAddr: '',
  language: 'en',
  trades: [],
  preferredWork: [],
  bilingual: false,
  notes: '',
  logo: '',
  companyDocs: '',
  ein: '',
  insuranceExpiration: '',
  accountOwner: '',
  leadAcqTime: '',
  projectView: 0,
  projectPost: 0,
  admin: false,
  paid: false
};

export interface FullUserData extends UserData {
  id: string;
}

export interface UserState {
  user: FullUserData;
  signedIn: boolean;
}

export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CLEAR_USER = 'CLEAR_USER';

interface AddUserAction {
  type: typeof ADD_USER;
  user: FullUserData;
}

interface UpdateUserAction {
  type: typeof UPDATE_USER;
  user: FullUserData;
}

interface ClearUserAction {
  type: typeof CLEAR_USER;
}

export type UserActionTypes =
  | UpdateUserAction
  | ClearUserAction
  | AddUserAction;
