export interface Trade {
  trade: string;
  category: TradeListCategory;
  sortOrder: number;
}

export interface SortedTradeList {
  trades: Trade[];
  sorted: boolean;
  sortedBy: string;
  filterString: string;
}

export interface OptionType {
  label: string;
  value: string;
  trade: Trade;
}

export type RawTradeList = typeof UnsortedTL;
export type TradeListCategory = keyof RawTradeList;

export function createTradeList(rawTrades: RawTradeList): SortedTradeList {
  const list: SortedTradeList = {
    trades: [],
    sorted: false,
    sortedBy: '',
    filterString: ''
  };

  Object.keys(rawTrades).forEach(key => {
    if (rawTrades[key as keyof RawTradeList].length > 0) {
      rawTrades[key as keyof RawTradeList].forEach(trade => {
        list.trades.push({
          trade,
          category: key as keyof RawTradeList,
          sortOrder: 0
        });
      });
    }
  });
  return list;
}

export function sortTradeList(
  TL: Trade[],
  sortType: 'alpha' | 'order'
): Trade[] {
  if (sortType === 'alpha') {
    return TL.sort((a, b) => {
      if (a.trade < b.trade) {
        return -1;
      } else if (a.trade > b.trade) {
        return 1;
      } else {
        return 0;
      }
    });
  } else {
    return TL.sort((a, b) => b.sortOrder - a.sortOrder);
  }
}

export const GetTradeListOptions = (tradeList: Trade[]) =>
  tradeList.map(trade => ({
    label: trade.trade,
    value: trade.trade,
    trade
  }));

export const UnsortedTL = {
  'Ceiling Wall': [
    'Acoustical Ceiling Treatment',
    'Acoustical Wall Treatment',
    'Ceiling Finish Supplementary Components',
    'Ceiling Finishes',
    'Ceiling Paneling',
    'Interior Fabrications',
    'Mud and Tape',
    'Plaster and Gypsum Board Finish',
    'Special Wall Surfacing',
    'Stone Facing',
    'Tile Wall Finish',
    'Wall Carpeting',
    'Wall Coverings',
    'Wall Finish Supplementary Components',
    'Wall Finishes',
    'Wall Paneling',
    'Wood Paneling',
    'Mud Only',
    'Tape Only'
  ],
  Cleaning: [
    'Building Cleaning',
    'Carpet and Upholstery Cleaning',
    'Cleaning',
    'Pest Control'
  ],
  Communications: [
    'Audio-Video Communication',
    'Audio-Video Systems',
    'Call Accounting',
    'Call Management',
    'Communications',
    'Communications Supplementary Components',
    'Data Communication Program and Integration Services',
    'Data Communications',
    'Data Communications Hardware',
    'Data Communications Network Equipment',
    'Data Communications Peripheral Data Equipment',
    'Data Communications Software',
    'Distributed Audio-Video Communications Systems',
    'Distributed Communications and Monitoring',
    'Distributed Systems',
    'Electronic Digital Systems',
    'Healthcare Communications and Monitoring',
    'Site Communications',
    'Site Communications Distribution',
    'Site Communications Structures',
    'Site Communications Systems',
    'Supplementary Components',
    'Voice Communications',
    'Voice Communications Messaging',
    'Voice Communications Switching and Routing Equipment',
    'Voice Communications Terminal Equipment',
    'Wireless Communications Distribution'
  ],
  Conveying: [
    'Conveying',
    'Dumbwaiters',
    'Elevators',
    'Escalators',
    'Horizontal Conveying',
    'Lifts',
    'Moving Ramps',
    'Moving Walks',
    'Passenger Loading Bridges',
    'People Movers',
    'Turntables',
    'Vertical Conveying Systems'
  ],
  Demolition: [
    'Bridge Demolition',
    'Building Demolition',
    'Dam Demolition',
    'Demolition',
    'Selective Bridge Demolition',
    'Selective Building Demolition',
    'Selective Demolition',
    'Selective Historic Demolition',
    'Selective Interior Demolition',
    'Structure Demolition',
    'Structure Moving',
    'Structure Raising',
    'Structure Relocation',
    'Tower Demolition'
  ],
  'Detection Alarm': [
    'Access Control',
    'Access Control and Intrusion Detection',
    'Breathing Air Replenishment Systems',
    'Detection and Alarm',
    'Electronic Detention Monitoring and Control',
    'Electronic Monitoring and Control',
    'Electronic Personal Protection',
    'Electronic Safety and Security',
    'Electronic Safety and Security Supplementary Components',
    'Electronic Surveillance',
    'Fire Detection and Alarm',
    'Fire Extinguisher Accessories',
    'Fire Extinguishers',
    'Fire Protection',
    'Fire Protection Cabinets',
    'Fire Protection Specialties',
    'Fire Suppression',
    'Fire Suppression Supplementary Components',
    'Fire-Extinguishing',
    'Fuel-Gas Detection and Alarm',
    'Fuel-Oil Detection and Alarm',
    'Integrated Automation',
    'Integrated Automation Control of Communication Systems',
    'Integrated Automation Control of Conveying Equipment',
    'Integrated Automation Control of Electrical Systems',
    'Integrated Automation Control of Electronic Safety and Security Systems',
    'Integrated Automation Control of Equipment',
    'Integrated Automation Control of Fire-Suppression Systems',
    'Integrated Automation Control of HVAC Systems',
    'Integrated Automation Control of Plumbing Systems',
    'Integrated Automation Facility Controls',
    'Integrated Automation Supplementary Components',
    'Intrusion Detection',
    'Radiation Detection and Alarm',
    'Refrigeration Detection and Alarm',
    'Supplementary Components',
    'Video Surveillance',
    'Water Intrusion Detection and Alarm',
    'Water-Based Fire-Suppression'
  ],
  Earthwork: [
    'Earth Dams',
    'Embankments',
    'Erosion and Sedimentation Controls',
    'Excavation and Fill',
    'Gabions',
    'Grading',
    'Riprap',
    'Rock Stabilization',
    'Site Earthwork',
    'Site Soil Treatment',
    'Slope Protection',
    'Soil Reinforcement',
    'Soil Stabilization',
    'Wetlands'
  ],
  Electric: [
    'Area Lighting',
    'Battery Equipment',
    'Branch Wiring for Lighting',
    'Branch Wiring System',
    'Building Illumination',
    'Cathodic Protection',
    'Electric Transmission and Distribution',
    'Electrical',
    'Electrical - Low Voltage',
    'Electrical Contracting',
    'Electrical Distribution System Instrumentation and Controls',
    'Electrical Rough In',
    'Electrical Service',
    'Electrical Service and Distribution',
    'Electrical Service and Distribution Supplementary Components',
    'Electrical Site Improvements',
    'Electrical Substations',
    'Electrical Switchgear and Protection Devices',
    'Electrical Transformers',
    'Electrical Utility Services',
    'Exterior Lighting Supplementary Components',
    'Facility Grounding',
    'Facility Power Generation',
    'Facility Power Generation Supplementary Components',
    'Flood Lighting',
    'Fuel Cells',
    'General Purpose Electrical Power',
    'General Purpose Electrical Power Supplementary Components',
    'High Voltage - more than 1000 volts',
    'Lighting',
    'Lighting Control',
    'Lighting Fixtures',
    'Lighting Supplementary Components',
    'Lightning Protection',
    'Medium Voltage - less Than 1000 Volts (Home)',
    'Miscellaneous Electrical Systems',
    'Miscellaneous Electrical Systems Supplementary Components',
    'Packaged Generator Assemblies',
    'Photovoltaic Collectors',
    'Power Distribution',
    'Power Filtering and Conditioning',
    'Residential Ceiling Fans',
    'Site Electric Distribution Systems',
    'Site Grounding',
    'Site Lighting',
    'Transfer Switches',
    'Transient Voltage Suppression',
    'Wiring Devices'
  ],
  Equipment: [
    'Agricultural Equipment',
    'Athletic Equipment',
    'Commercial Equipment',
    'Commercial Laundry and Dry Cleaning Equipment',
    'Decontamination Equipment',
    'Detention Equipment',
    'Educational and Scientific Equipment',
    'Entertainment and Recreational Equipment',
    'Equipment',
    'Equipment and Furnishings',
    'Foodservice Equipment',
    'Healthcare Equipment',
    'Horticultural Equipment',
    'Hospitality Equipment',
    'Institutional Equipment',
    'Interior Parking Control Equipment',
    'Interior Pedestrian Control Equipment',
    'Loading Dock Equipment',
    'Maintenance Equipment',
    'Mercantile and Service Equipment',
    'Musical Equipment',
    'Office Equipment',
    'Other Equipment',
    'Photographic Processing Equipment',
    'Postal, Packaging, and Shipping Equipment',
    'Recreational Equipment',
    'Refrigerated Display Equipment',
    'Religious Equipment',
    'Residential Equipment',
    'Retractable Stairs',
    'Security Equipment',
    'Solid Waste Handling Equipment',
    'Teller and Service Equipment',
    'Theater and Stage Equipment',
    'Unit Kitchens',
    'Vault Equipment',
    'Vehicle and Pedestrian Equipment',
    'Vehicle Servicing Equipment'
  ],
  'Exterior Enclosure': [
    'Bird Control Devices',
    'Exterior Balcony Walls and Railings',
    'Exterior Door Supplementary Components',
    'Exterior Doors and Grilles',
    'Exterior Entrance Doors',
    'Exterior Fabrications',
    'Exterior Fixed Grilles and Screens',
    'Exterior Gates',
    'Exterior Grilles',
    'Exterior Louvers',
    'Exterior Louvers and Vents',
    'Exterior Opening Protection Devices',
    'Exterior Oversize Doors',
    'Exterior Special Function Doors',
    'Exterior Utility Doors',
    'Exterior Vents',
    'Exterior Wall Appurtenances',
    'Exterior Wall Specialties',
    'Garage Doors'
  ],
  'Floors Stairs': [
    'Athletic Flooring',
    'Carpeted Stair Finish',
    'Carpeting',
    'Entrance Flooring',
    'Flooring',
    'Flooring Supplementary Components',
    'Flooring Treatment',
    'Fluid-Applied Flooring',
    'Masonry Flooring',
    'Masonry Stair Finish',
    'Resilient Flooring',
    'Resilient Stair Finish',
    'Specialty Flooring',
    'Stair Finishes',
    'Terrazzo Flooring',
    'Terrazzo Stair Finish',
    'Wood Flooring',
    'Wood Stair Finish'
  ],
  'Foundation Substructure': [
    'Anchor Tiebacks',
    'Backfill and Compaction',
    'Bored Piles',
    'Caissons',
    'Cofferdams',
    'Column Foundations',
    'Construction Dewatering',
    'Cribbing and Walers',
    'Driven Piles',
    'Excavation Support',
    'Foundation Anchors',
    'Foundations',
    'Grade Beams',
    'Ground Freezing',
    'Mud Slab',
    'Perimeter Insulation',
    'Pile Caps',
    'Pits and Bases',
    'Raft Foundations',
    'Slab Trenches',
    'Slab-On-Grade Supplementary Components',
    'Slabs-On-Grade',
    'Slurry Walls',
    'Soil Treatment',
    'Special Foundation Walls',
    'Special Foundations',
    'Standard Foundation Supplementary Components',
    'Standard Foundations',
    'Standard Slabs-on-Grade',
    'Structural Slabs-on-Grade',
    'Subbase Layer',
    'Subgrade Enclosure Wall Construction',
    'Subgrade Enclosure Wall Interior Skin',
    'Subgrade Enclosure Wall Supplementary Components',
    'Subgrade Enclosures',
    'Substructure',
    'Substructure Excavation',
    'Substructure Related Activities',
    'Underpinning',
    'Vapor Retarder',
    'Wall Foundations',
    'Walls for Subgrade Enclosures',
    'Waterproofing'
  ],
  Furnishings: [
    'Accessories',
    'Casework',
    'Fixed Art',
    'Fixed Furnishings',
    'Fixed Multiple Seating',
    'Furnishings',
    'Furniture',
    'Movable Art',
    'Movable Furnishings',
    'Movable Multiple Seating',
    'Other Fixed Furnishings',
    'Other Movable Furnishings',
    'Residential Appliances',
    'Window Treatments'
  ],
  Garbage: [
    'Septic Tank Services and Sewer Pipe Cleaning',
    'Solid Waste Collection',
    'Waste Management',
    'Waste Management - Waste Collection - Dumpster',
    'Waste Management Services'
  ],
  Glass: [
    'Exterior Fixed Windows',
    'Exterior Operating Windows',
    'Exterior Special Function Windows',
    'Exterior Window Wall',
    'Exterior Windows',
    'Glass Doors',
    'Glazing Contracting - Other',
    'Interior Fixed Windows',
    'Interior Operating Windows',
    'Interior Special Function Windows',
    'Interior Window Supplementary Components',
    'Interior Windows',
    'Mirrors',
    'Sky Lights',
    'Windows',
    'Windows, Glass, and Glazing Contracting'
  ],
  HVAC: [
    'Air-to-Air Energy Recovery',
    'Central Cooling',
    'Cooling System Supplementary Components',
    'Cooling Systems',
    'Decentralized Cooling',
    'Decentralized Heating Equipment',
    'Evaporative Air-Cooling',
    'Exhaust Air',
    'Facility Distribution Systems Supplementary Components',
    'Facility Fuel Systems',
    'Facility HVAC Distribution Systems',
    'Facility Hydronic Distribution',
    'Facility Steam Distribution',
    'Fuel Piping',
    'Fuel Pumps',
    'Fuel Storage Tanks',
    'Heat Generation',
    'Heating System Supplementary Components',
    'Heating Systems',
    'Heating, Ventilation and Air Conditioning',
    'Heating, Ventilation, and Air Conditioning (HVAC)',
    'HVAC Air Cleaning',
    'HVAC Air Distribution',
    'HVAC Contracting',
    'Outside Air',
    'Refrigeration',
    'Refrigeration Contracting',
    'Return Air',
    'Snow Melting',
    'Special Purpose HVAC Systems',
    'Supply Air',
    'Thermal Cooling Storage',
    'Thermal Heat Storage',
    'Ventilation',
    'Ventilation Supplementary Components'
  ],
  Insulation: ['Insulating', 'Insulating Contracting'],
  'Interior Construction': [
    'Access Flooring',
    'Acoustical Suspended Ceilings',
    'Cabinet Maker',
    'Ceiling Suspension Components',
    'Finish Carpentry Contracting',
    'Interior Access Doors and Panels',
    'Interior Coiling Doors',
    'Interior Construction',
    'Interior Demountable Partitions',
    'Interior Door Supplementary Components',
    'Interior Doors',
    'Interior Entrance Doors',
    'Interior Finishes',
    'Interior Fixed Partitions',
    'Interior Folding Doors',
    'Interior Gates',
    'Interior Glazed Partitions',
    'Interior Grilles',
    'Interior Grilles and Gates',
    'Interior Louvers',
    'Interior Operable Partitions',
    'Interior Panel Doors',
    'Interior Partition Supplementary Components',
    'Interior Partitions',
    'Interior Railings and Handrails',
    'Interior Screens',
    'Interior Sliding Doors',
    'Interior Special Function Doors',
    'Interior Specialties',
    'Interior Swinging Doors',
    'Interior Trim',
    'Interiors',
    'Platform/Stage Floors',
    'Raised Floor Construction',
    'Special Function Suspended Ceilings',
    'Specialty Suspended Ceilings',
    'Storage Specialties',
    'Suspended Ceiling Construction',
    'Suspended Plaster and Gypsum Board Ceilings'
  ],
  Labor: [
    'Construction Labor Equipment Operation',
    'General Construction Labor',
    'Labor Contracting'
  ],
  Landscape: [
    'Covers and Shelters',
    'Exterior Fountains',
    'Exterior Gas Lighting',
    'Exterior Signage',
    'Fences and Gates',
    'Flagpoles',
    'Grounds Maintenance Workers',
    'Landscape Contractor',
    'Landscape Lighting',
    'Landscaping',
    'Landscaping Activities',
    'Landscaping Arborist',
    'Landscaping Asphalt (Asphalt Seal)',
    'Landscaping Contractor',
    'Landscaping Draining',
    'Landscaping Fencing',
    'Landscaping Mailbox',
    'Landscaping Pavement contractor (concrete flatwork)',
    'Planting Accessories',
    'Planting Irrigation',
    'Plants',
    'Retaining Walls',
    'Site Bridges',
    'Site Development',
    'Site Equipment',
    'Site Furnishings',
    'Site Screening Devices',
    'Site Specialties',
    'Turf and Grasses'
  ],
  Masonry: [
    'Concrete Cement Finishing Contracting',
    'Concrete Contracting',
    'Concrete Flatwork',
    'General Concrete Contracting',
    'Masonry',
    'Masonry Contracting'
  ],
  'Material Moving Handling': [
    'Baggage Handling Equipment',
    'Chutes',
    'Conveyors',
    'Cranes',
    'Derricks',
    'Dredge, Excavating, and Loading Machine Operation',
    'Hoist and Winch Operation',
    'Hoists',
    'Industrial Truck and Tractor Operation',
    'Laborers and Material Moving, Hand',
    'Material Handling',
    'Material Moving Contracting',
    'Pneumatic Tube Systems'
  ],
  Paint: [
    'Ceiling Painting and Coating',
    'Exterior Painting',
    'Interior Painting',
    'Other Painting',
    'Trim Painting',
    'Wall Painting and Coating'
  ],
  Plumbing: [
    'Boilermaker',
    'Building Support Plumbing System Supplementary Components',
    'Building Support Plumbing Systems',
    'Chemical-Waste Systems',
    'Compressed-Air Systems',
    'Domestic Water Distribution',
    'Domestic Water Distribution Supplementary Components',
    'Domestic Water Equipment',
    'Domestic Water Piping',
    'Facility Potable-Water Storage Tanks',
    'Facility Stormwater Drains',
    'Finish Plumbing',
    'Gas Systems',
    'General Service Compressed-Air',
    'Gray Water Systems',
    'Interior Gas Lighting',
    'Plumbing',
    'Plumbing - Other',
    'Plumbing Fixtures',
    'Process Support Plumbing System Supplementary Components',
    'Process Support Plumbing Systems',
    'Processed Water Systems',
    'Rough in Plumbing',
    'Sanitary Drainage',
    'Sanitary Drainage Supplementary Components',
    'Sanitary Sewerage Equipment',
    'Sanitary Sewerage Piping',
    'Stormwater Drainage Equipment',
    'Stormwater Drainage Piping',
    'Toilet, Bath, and Laundry Accessories',
    'Vacuum Systems'
  ],
  Recreational: [
    'Amusement Park Structures and Equipment',
    'Animal Containment',
    'Aquariums',
    'Arena Football Boards',
    'Athletic and Recreational Court Walls',
    'Athletic and Recreational Special Construction',
    'Athletic Areas',
    'Athletic, Recreational, and Playfield Areas',
    'Demountable Athletic Surfaces',
    'Floor Sockets',
    'Ice Rinks',
    'Indoor Soccer Boards',
    'Interior Fountains',
    'Interior Water Features',
    'Playfield Areas',
    'Pools',
    'Recreational Areas',
    'Safety Netting',
    'Special Facility Components'
  ],
  Remediation: [
    'Asbestos Remediation',
    'Biological Decontamination',
    'Chemical Decontamination',
    'Contaminated Site Material Removal',
    'Facility Remediation',
    'Hazardous Materials Remediation',
    'Hazardous Waste Drum Handling',
    'Lead Remediation',
    'Mold Remediation',
    'Physical Decontamination',
    'Polychlorinate Biphenyl Remediation',
    'Remediation Soil Stabilization',
    'Sinkhole Remediation',
    'Site Containment',
    'Site Remediation',
    'Thermal Decontamination',
    'Transportation and Disposal of Hazardous Materials',
    'Water Remediation'
  ],
  Restoration: [
    'Concrete Restoration',
    'Facility Restoration',
    'Masonry Restoration',
    'Parking Restoration',
    'Restoration Services',
    'Restoration Services - General'
  ],
  Roadway: [
    'Airfield Lighting',
    'Airfield Signaling and Control Equipment',
    'Airfields',
    'Aviation Pavement',
    'Aviation Pavement Appurtenances',
    'Aviation Pavement Curbs and Gutters',
    'Exterior Parking Control Equipment',
    'Exterior Pedestrian Control Equipment',
    'Exterior Steps and Ramps',
    'Parking Lot Appurtenances',
    'Parking Lot Curbs and Gutters',
    'Parking Lot Lighting',
    'Parking Lot Pavement',
    'Parking Lots',
    'Pedestrian Pavement',
    'Pedestrian Pavement Appurtenances',
    'Pedestrian Pavement Curbs and Gutters',
    'Pedestrian Plazas and Walkways',
    'Plaza and Walkway Lighting',
    'Roadway Appurtenances',
    'Roadway Curbs and Gutters',
    'Roadway Lighting',
    'Roadway Pavement',
    'Roadways',
    'Vehicle Fare Collection'
  ],
  Roof: [
    'Canopy Roofing',
    'Exterior Horizontal Enclosures',
    'Gutters',
    'Horizontal Enclosure Supplementary Components',
    'Horizontal Opening Supplementary Components',
    'Horizontal Openings',
    'Horizontal Waterproofing Membrane',
    'Low-Slope Roofing',
    'Rainwater Management',
    'Roof Accessories',
    'Roof Appurtenances',
    'Roof Specialties',
    'Roof Windows and Skylights',
    'Roofing',
    'Roofing Supplementary Components',
    'Steep Slope Roofing',
    'Traffic Bearing Coatings',
    'Traffic Bearing Horizontal Enclosures',
    'Vents and Hatches',
    'Wear Surfaces'
  ],
  Scaffolding: [
    'Building Envelope Access',
    'Elevating Platforms',
    'Operable Access Systems',
    'Powered Scaffolding',
    'Rope Climbers',
    'Suspended Scaffolding'
  ],
  'Sheet Metal': [
    'Sheet Metal Working (tin knocker)',
    'Sheet Metal Working Contracting'
  ],
  Siding: [
    'Brick Siding',
    'Exterior Wall Veneer',
    'Metal Siding',
    'Stucco Siding',
    'Wood Siding',
    'Vinyl Siding',
    'Fiber Cement Siding',
    'Engineered Wood Siding',
    'Stone Siding',
    'Cladding'
  ],
  'Site Prep': [
    'Clearing and Grubbing',
    'Control and Communication Service',
    'Demolition',
    'Dumpster Contractor',
    'Earth Stripping and Stockpiling',
    'Erosion & Stormwater Contractor',
    'Fence Erection / Construction Fencing',
    'Foundation Preparation',
    'Hazardous Material Abatement',
    'Hydroligical Control (Dewatering)',
    'Infrastructure Demolition',
    'Remediation',
    'Seismic Control',
    'Selective Site Demolition',
    'Sewerage / Ecological Toilets / Portalets',
    'Shoring',
    'Site Clearing',
    'Site Element Relocations',
    'Site Elements Demolition',
    'Site Improvements',
    'Site Preparation',
    'Site Preparation - Other',
    'Site Preparation Contracting',
    'Sitework',
    'Street Sweaping',
    'Temporary Electric Service',
    'Temporary Utilities',
    'Temporary Water Service',
    'Tree and Shrub Removal and Trimming',
    'Utility Demolition',
    'Utility Relocation'
  ],
  'Special Construction': [
    'Building Modules',
    'Earth Movement Monitoring',
    'Fabric Structures',
    'Geodesic Structures',
    'Integrated Construction',
    'Manufactured Canopies',
    'Manufactured/Fabricated Rooms',
    'Manufacturer-Engineered Structures',
    'Meteorological Instrumentation',
    'Miscellaneous Site Construction',
    'Modular Mezzanines',
    'Pedestrian Tunnels',
    'Radiation Protection',
    'Rammed Earth Construction',
    'Seismic Control',
    'Seismic Instrumentation',
    'Service Tunnels',
    'Sound and Vibration Control',
    'Space Frames',
    'Special Construction',
    'Special Construction and Demolition',
    'Special Function Construction',
    'Special Instrumentation',
    'Special Structures',
    'Stress Instrumentation',
    'Towers',
    'Tunnel Construction Related Activities',
    'Tunnels',
    'Vehicular Tunnels'
  ],
  'Special Interior': [
    'Compartments and Cubicles',
    'Fire Place Installation and Maintenance',
    'Fireplaces and Stoves',
    'Home Appliance Installation and Maintenance',
    'Home Theater Installers',
    'Information Specialties',
    'Move In Contracting',
    'Movers, Appliance Movers and Funiture Movers',
    'Other Interior Specialties',
    'Safety Specialties',
    'Service Walls',
    'Wall and Door Protection'
  ],
  Structure: [
    'Balcony Floor Construction',
    'Canopy Construction',
    'Doors',
    'Equipment Screens',
    'Exterior Bulkheads',
    'Exterior Ceilings',
    'Exterior Soffits',
    'Exterior Vertical Enclosures',
    'Exterior Wall Construction',
    'Exterior Wall Interior Skin',
    'Exterior Wall Opening Supplementary Components',
    'Exterior Wall Supplementary Components',
    'Exterior Walls',
    'Fabricated Exterior Wall Assemblies',
    'Fire Escapes',
    'Floor Construction',
    'Floor Construction Supplementary Components',
    'Floor Decks, Slabs, and Toppings',
    'Floor Structural Frame',
    'Ladders',
    'Metal Walkways',
    'Mezzanine Floor Construction',
    'Overhead Exterior Enclosures',
    'Parapets',
    'Ramps',
    'Roof Construction',
    'Roof Construction Supplementary Components',
    'Roof Decks, Slabs, and Sheathing',
    'Roof Structural Frame',
    'Rough Carpentry Contracting',
    'Shell',
    'Stair Construction',
    'Stair Railings',
    'Stair Soffits',
    'Stairs',
    'Superstructure',
    'Trusses'
  ],
  'Substructure Mitigation': [
    'Building Subdrainage',
    'Foundation Drainage',
    'Methane Mitigation',
    'Off-Gassing Mitigation',
    'Radon Mitigation',
    'Underslab Drainage',
    'Water and Gas Mitigation'
  ],
  Supplier: [
    'Appliance Supplier',
    'Building Product Manufacturing and Supply',
    'Construction Equipment Manufacturing and Supply',
    'Fabrication Services and Supply',
    'Furniture Supplier',
    'Manufacturing Services and Supply',
    'Process Manufacturing',
    'Supply Services',
    'Tile	Tile Flooring',
    'Tile Stair Finish',
    'Tile Wall Finish'
  ],
  Utilities: [
    'Culverts',
    'Electric Utility',
    'Electrical Utility Services',
    'Energy (Utility) Service Contracting',
    'Environmental Energy Service',
    'Gas Utility',
    'Geothermal Service',
    'Liquid and Gas Site Utilities',
    'Liquid and Gas Site Utilities Supplementary Components',
    'Sanitary Sewerage Lagoons',
    'Sanitary Sewerage Piping',
    'Sanitary Sewerage Structures',
    'Sanitary Sewerage Utilities',
    'Sanitary Sewerage Utility Connection',
    'Sewer Utility',
    'Site Aviation Fuel Distribution',
    'Site Diesel Fuel Distribution',
    'Site Domestic Water Distribution',
    'Site Energy Distribution',
    'Site Fire Protection Water Distribution',
    'Site Fuel Distribution',
    'Site Fuel-Oil Distribution',
    'Site Gas Distribution',
    'Site Gasoline Distribution',
    'Site Hydronic Cooling Distribution',
    'Site Hydronic Heating Distribution',
    'Site Irrigation Water Distribution',
    'Site Steam Energy Distribution',
    'Site Storm Water Drains',
    'Site Subdrainage',
    'Solar Photovoltaic Service',
    'Storm Drainage Piping',
    'Storm Drainage Ponds and Reservoirs',
    'Storm Drainage Pumps',
    'Storm Drainage Utilities',
    'Storm Drainage Utility Connection',
    'Supplementary Components',
    'Underground Utility Services',
    'Utility Septic Tanks',
    'Utility Services',
    'Water Utilities',
    'Water Utility',
    'Wind Turbine Service'
  ],
  Welding: [
    'Iron Work and Welding Contracting',
    'Iron Working and Welding',
    'Reinforcing Iron and Rebar Fabrication'
  ]
};

export const TradeListCategories: TradeListCategory[] = [
  'Ceiling Wall',
  'Cleaning',
  'Communications',
  'Conveying',
  'Demolition',
  'Detection Alarm',
  'Earthwork',
  'Electric',
  'Equipment',
  'Exterior Enclosure',
  'Floors Stairs',
  'Foundation Substructure',
  'Furnishings',
  'Garbage',
  'Glass',
  'HVAC',
  'Insulation',
  'Interior Construction',
  'Labor',
  'Landscape',
  'Masonry',
  'Material Moving Handling',
  'Paint',
  'Plumbing',
  'Recreational',
  'Remediation',
  'Restoration',
  'Roadway',
  'Roof',
  'Scaffolding',
  'Sheet Metal',
  'Siding',
  'Site Prep',
  'Special Construction',
  'Special Interior',
  'Structure',
  'Substructure Mitigation',
  'Supplier',
  'Utilities',
  'Welding'
];
