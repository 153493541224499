import React from 'react';
import { ConstructionType } from '../../Redux/Store/Project';
import {
  Dialog,
  DialogTitle,
  IconButton,
  Button,
  DialogContent
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { Trans } from '@lingui/react';
import Page from '../Page';

/**
 * IFrames Record maps the construction type to the correct iframe
 * for a user to create a new project
 */
const IFrames: Record<ConstructionType, string> = {
  Remodel: 'shrlXOrmxFSkCovXK',
  Retrofit: 'shrlXOrmxFSkCovXK',
  Restoration: 'shrlXOrmxFSkCovXK',
  Renovation: 'shrlXOrmxFSkCovXK',
  Maintenance: 'shr9NkFkn8XOJyDzO',
  'New Construction Commercial': 'shrqEiqE1J05aXD4T',
  'New Construction Residential': 'shrqEiqE1J05aXD4T',
  'New Construction Commercial Residential': 'shrqEiqE1J05aXD4T'
};

/**
 * NewProjectIFrame is a page that just has them click a button
 * to select the construction type of the new project
 * then opens a dialog with the correct iframe for them to
 * fill out their new project's information
 */
const NewProjectIFrame: React.FC = () => {
  const [isOpen, setOpen] = React.useState(false);
  const [cType, setCType] = React.useState<ConstructionType>('Remodel');

  return (
    <Page title='New Project'>
      <div style={{ margin: 'auto', marginTop: '15px' }}>
        <Button
          style={{ margin: 'auto', display: 'block' }}
          onClick={() => {
            setCType('New Construction Residential');
            setOpen(true);
          }}
        >
          New Construction
        </Button>
        <Button
          style={{ margin: 'auto', display: 'block' }}
          onClick={() => {
            setCType('Remodel');
            setOpen(true);
          }}
        >
          Remodel
        </Button>
        <Button
          style={{ margin: 'auto', display: 'block' }}
          onClick={() => {
            setCType('Maintenance');
            setOpen(true);
          }}
        >
          Maintenance
        </Button>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>
          <Trans>New Project</Trans>
          <IconButton
            aria-label='Close'
            type='button'
            color='secondary'
            style={{ position: 'absolute', right: '2.5%', top: '1%' }}
            onClick={() => setOpen(false)}
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <script src='https://static.airtable.com/js/embed/embed_snippet_v1.js'></script>
          <iframe
            className='airtable-embed airtable-dynamic-height'
            src={'https://airtable.com/embed/' + IFrames[cType]}
            frameBorder='0'
            width='100%'
            height='1422'
            style={{ background: 'transparent', border: '1px solid #ccc' }}
            title={cType + ' project'}
          />
        </DialogContent>
      </Dialog>
    </Page>
  );
};

export default NewProjectIFrame;
