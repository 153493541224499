import React, { useContext } from 'react';
import { setupI18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Firebase, { FirebaseContext } from '../Firebase';
import { RouteComponentProps } from 'react-router';
import { History } from 'history';
import Page from '../Page';
import FormItem from '../Form/FormItem';
import { validateEmail } from '../Form/Validators';

const i18n = setupI18n();

interface InitialForm {
  email: string;
  error: string;
}

class EmailSignInForm extends React.Component<
  { firebase: Firebase; history: History },
  InitialForm
> {
  constructor(props: { firebase: Firebase; history: History }) {
    super(props);
    this.state = {
      email: '',
      error: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  public componentDidMount() {
    // Confirm the link is a sign-in with email link.
    if (this.props.firebase.auth.isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      const email = window.localStorage.getItem('emailForSignIn');
      if (email) {
        // The client SDK will parse the code from the link for you.
        this.props.firebase.auth
          .signInWithEmailLink(email, window.location.href)
          .then(result => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
          })
          .catch(error => {
            console.log(error);
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          });
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
      }
    }
  }

  public handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // The client SDK will parse the code from the link for you.
    if (this.props.firebase.auth.isSignInWithEmailLink(window.location.href)) {
      this.props.firebase.auth
        .signInWithEmailLink(this.state.email, window.location.href)
        .then(user => {
          if (user && user.user) {
            if (window.location.href.includes('first-sign-in-email')) {
              this.props.firebase.auth.onAuthStateChanged(() => {
                if (this.props.firebase.auth.currentUser) {
                  this.props.history.push('/set-password');
                } else {
                  this.props.history.push('/');
                }
              });
            } else {
              this.props.history.push('/');
            }
          }
        })
        .catch(error => {
          if (error.code === 'auth/expired-action-code') {
            this.setState({
              error: 'Link has expired, send another email?'
            });
          } else if (error.code === 'auth/invalid-email') {
            this.setState({
              error: 'Invalid email, please double-check and try again.'
            });
          } else {
            this.setState({
              error: 'There was an issue signing you in, send another email?'
            });
          }
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  };

  public handleError = (error: string) => {
    this.setState({ error });
  };

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [event.target.name as keyof InitialForm]: event.target.value
    } as Pick<InitialForm, keyof InitialForm>);
  };

  private handleSendAnother = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const url = window.location.href.includes('first-sign-in-email')
      ? 'https://market.manybuild.app/first-sign-in-email/'
      : 'https://market.manybuild.app/email-sign-in/';
    this.props.firebase.auth
      .sendSignInLinkToEmail(this.state.email, {
        url,
        handleCodeInApp: true
      })
      .then(() => {
        window.localStorage.setItem('emailForSignIn', this.state.email);
        alert('Email sent!');
      })
      .catch(err => console.log(err));
  };

  public render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        style={{ width: '75%', margin: 'auto' }}
      >
        <FormItem
          fieldId='emailField'
          value={this.state.email}
          name='email'
          type='email'
          label={i18n._(t`Email`)}
          helper='Your registered email address'
          update={this.handleChange}
          handleError={this.handleError}
          validate={validateEmail}
        />

        <FormControl fullWidth style={{ marginTop: '15px' }}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            style={{ margin: 'auto' }}
            disabled={this.state.error.length > 0}
          >
            <Trans>Sign In</Trans>
          </Button>
        </FormControl>

        {this.state.error.length > 0 ? (
          <h6 style={{ textAlign: 'center' }} color='red'>
            {this.state.error}
          </h6>
        ) : null}

        {this.state.error.length > 0 && validateEmail(this.state.email) ? (
          <FormControl fullWidth style={{ marginTop: '25px' }}>
            <Button
              variant='contained'
              color='secondary'
              style={{ margin: 'auto' }}
              onClick={this.handleSendAnother}
            >
              <Trans>Send Another Email</Trans>
            </Button>
          </FormControl>
        ) : null}
      </form>
    );
  }
}

const EmailSignInPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const fb = useContext(FirebaseContext);
  return (
    <Page title={i18n._(t`Sign in with Email Link`)}>
      <EmailSignInForm firebase={fb} history={props.history} />
    </Page>
  );
};

export default EmailSignInPage;
