import { combineReducers } from 'redux';
import { userReducer } from './UserReducer';
import { projectReducer } from './ProjectReducer';
import { bidReducer } from './BidReducer';

export const rootReducer = combineReducers({
  Auth: userReducer,
  Project: projectReducer,
  Bid: bidReducer
});

export type AppState = ReturnType<typeof rootReducer>;
