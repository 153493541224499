import React from 'react';
import Firebase from '../Firebase';
import { Trans } from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import { Link } from 'react-router-dom';
import { FullProject, DisplayProject } from '../../Redux/Store/Project';
import { FullBid } from '../../Redux/Store/Bid';
import AwardModal from './AwardModal';

const ProjectAdmin = (
  proj: DisplayProject,
  handleDelete: (id: string) => void,
  handleApprove: (id: string) => void,
  handleAward: (id: string, awardValue: number) => void,
  retrieveApprovedBid: (id: string) => number
) => (
  <TableRow key={proj.id}>
    <TableCell component='th' scope='row'>
      <Link to={'/project/' + proj.id}>{proj.id}</Link>
    </TableCell>
    <TableCell align='center'>{proj.cards.length}</TableCell>
    <TableCell align='center'>
      {proj.approved
        ? proj.awarded
          ? 'Awarded'
          : 'Approved'
        : 'Pending Admin Approval'}
    </TableCell>
    <TableCell align='center'>{proj.public ? 'Public' : 'Private'}</TableCell>
    <TableCell align='center'>
      <IconButton
        aria-label='Approve'
        type='button'
        color='primary'
        style={{ margin: 'auto', display: 'block' }}
        onClick={event => {
          handleApprove(proj.id);
        }}
      >
        <CheckIcon />
      </IconButton>
    </TableCell>
    <TableCell align='center'>
      <IconButton
        aria-label='Award'
        type='button'
        color='primary'
        style={{ margin: 'auto', display: 'block' }}
        onClick={event => {
          handleAward(proj.id, proj.awardValue);
        }}
      >
        <CheckIcon />
      </IconButton>
    </TableCell>
    <TableCell align='center'>
      <IconButton
        aria-label='Delete'
        type='button'
        color='secondary'
        style={{ margin: 'auto', display: 'block' }}
        onClick={event => {
          handleDelete(proj.id);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);

interface ProjectPanelProps {
  firebase: Firebase;
}

interface ProjectPanelState {
  projects: DisplayProject[];
  awardProjId: string;
  awardValue: number;
}

export default class ProjectPanel extends React.Component<
  ProjectPanelProps,
  ProjectPanelState
> {
  constructor(props: ProjectPanelProps) {
    super(props);
    this.state = {
      projects: [],
      awardProjId: '',
      awardValue: 0
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleAward = this.handleAward.bind(this);
    this.retrieveApprovedBid = this.retrieveApprovedBid.bind(this);
  }

  public componentDidMount() {
    this.props.firebase.firestore
      .collection('projects')
      .get()
      .then(docsnap => {
        const projects: DisplayProject[] = [];
        docsnap.forEach(doc => {
          const proj = doc.data() as FullProject;
          projects.push({
            ...proj,
            cards: proj.projects.en
          });
        });
        this.setState({
          projects
        });
      })
      .catch(err => console.log(err));
  }

  public handleDelete = (id: string) => {
    const sure = window.confirm(
      'Are you sure you would like to delete this project?'
    );
    if (sure) {
      this.props.firebase.firestore
        .collection('projects')
        .doc(id)
        .get()
        .then(snap => {
          snap.ref
            .delete()
            .then(() => {
              this.props.firebase.firestore
                .collection('bids')
                .where('projectId', '==', id)
                .get()
                .then(docsnap => {
                  docsnap.forEach(qsnap => {
                    qsnap.ref.delete().catch(err => console.log(err));
                  });
                })
                .catch(err => console.log(err));
              alert('Project deleted.');
            })
            .catch(err => console.log(err));
        })
        .catch(err => console.log(err));
    }
  };

  public retrieveApprovedBid = (id: string): number => {
    let bidAmt = 0;
    this.props.firebase.firestore
      .collection('bids')
      .where('projectId', '==', id)
      .where('approved', '==', true)
      .get()
      .then(docsnap => {
        const bid: FullBid[] = [];
        docsnap.forEach(doc => {
          bid.push({ ...(doc.data() as FullBid) });
        });
        bidAmt = bid[0].bidAmt;
      })
      .catch(err => console.log(err));
    return bidAmt;
  };

  public handleAward = (id: string, awardValue: number) => {
    this.props.firebase.firestore
      .collection('projects')
      .doc(id)
      .update({ awarded: awardValue > 0, awardValue })
      .then(() => {
        this.setState({
          projects: this.state.projects.map(project =>
            project.id === id
              ? { ...project, awarded: awardValue > 0, awardValue }
              : project
          )
        });
        console.log(this.state.projects);
      })
      .catch(err => console.log(err));
  };

  public handleApprove = (id: string) => {
    this.props.firebase.firestore
      .collection('projects')
      .doc(id)
      .update({ approved: true })
      .then(() => {
        this.setState({
          projects: this.state.projects.map(project =>
            project.id === id ? { ...project, approved: true } : project
          )
        });
      })
      .catch(err => console.log(err));
  };

  public render() {
    return (
      <React.Fragment>
        <AwardModal
          projectId={this.state.awardProjId}
          initValue={this.state.awardValue}
          handleAward={this.handleAward}
          firebase={this.props.firebase}
          closeModal={() =>
            this.setState({
              awardProjId: '',
              awardValue: 0
            })
          }
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Project</Trans>
              </TableCell>
              <TableCell align='center'>
                <Trans>Company</Trans>
              </TableCell>
              <TableCell align='center'>
                <Trans>Rooms</Trans>
              </TableCell>
              <TableCell align='center'>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell align='center'>
                <Trans>Public</Trans>
              </TableCell>
              <TableCell align='center'>
                <Trans>Approve</Trans>
              </TableCell>
              <TableCell align='center'>
                <Trans>Award</Trans>
              </TableCell>
              <TableCell align='center'>
                <Trans>Delete</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.projects.map(proj =>
              ProjectAdmin(
                proj,
                this.handleDelete,
                this.handleApprove,
                (id: string, awardValue: number) =>
                  this.setState({ awardProjId: id, awardValue }),
                this.retrieveApprovedBid
              )
            )}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
}
