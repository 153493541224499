import React, { useContext, useState } from 'react';
import { setupI18n } from '@lingui/core';
import { Redirect } from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Trans, t } from '@lingui/macro';
import { FirebaseContext } from '../Firebase';
import Page from '../Page';
import ProjectPanel from './ProjectPanel';
import { UserPanel } from '.';
import EditUserForm from '../User/EditUser';
import { FullUserData } from '../../Redux/Store/User';
import { AppState } from '../../Redux/Reducer/RootReducer';
import { connect } from 'react-redux';

const i18n = setupI18n();
const mapStateToProps = (state: AppState) => ({
  user: state.Auth.user
});
const AdminPage: React.FC<{ user: FullUserData }> = (props: {
  user: FullUserData;
}) => {
  const firebase = useContext(FirebaseContext);
  const [userEdit, updateUserEdit] = useState('');

  if (firebase.authStateReported && props.user.admin) {
    return (
      <Page title={i18n._(t`Admin`)}>
        <h3 style={{ textAlign: 'center' }}>
          <Trans>Projects</Trans>
        </h3>
        <ProjectPanel firebase={firebase} />
        <h3 style={{ textAlign: 'center' }}>
          <Trans>Users</Trans>
        </h3>
        <UserPanel firebase={firebase} updateUserEdit={updateUserEdit} />
        <Dialog
          open={userEdit.length > 0}
          aria-labelledby='User edit form'
          aria-describedby='User-edit-form-description'
          onClose={event => {
            updateUserEdit('');
          }}
        >
          <DialogTitle>
            <Trans>Edit User</Trans>
            <IconButton
              aria-label='Close'
              type='button'
              color='secondary'
              style={{ position: 'absolute', right: '2.5%', top: '1%' }}
              onClick={event => {
                updateUserEdit('');
              }}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <EditUserForm
            closeModal={updateUserEdit}
            firebase={firebase}
            id={userEdit}
            admin={true}
          />
        </Dialog>
      </Page>
    );
  } else {
    return <Redirect to='/' />;
  }
};

export default connect(mapStateToProps, null)(AdminPage);
