import React from 'react';
import Grid from '@material-ui/core/Grid';
import Firebase from '../Firebase';
import LoadingIcon from '../LoadingIcon';
import {
  DisplayProject,
  ConstructionType,
  SupportedLang
} from '../../Redux/Store/Project';
import { AppState } from '../../Redux/Reducer/RootReducer';
import { LoadMarketProjects } from '../../Redux/Action/ProjectActions';
import { connect } from 'react-redux';
import './Market.css';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import MarketCard from './MarketCard';

const styles = createStyles({
  root: {
    height: '20% !important',
    textAlign: 'center'
  },
  tile: {
    overflow: 'visible'
  }
});

interface MarketProps {
  firebase: Firebase;
  lang: SupportedLang;
  loadMarketProjects: (projects: DisplayProject[]) => void;
  marketProjects: DisplayProject[];
  market: ConstructionType[];
}

interface MarketState {
  loading: boolean;
}

const mapStateToProps = (state: AppState) => ({
  marketProjects: state.Project.marketProjects,
  market: state.Project.market,
  lang: state.Project.lang
});

const mapDispatchToProps = (dispatch: any) => ({
  loadMarketProjects: (projects: DisplayProject[]) =>
    dispatch(LoadMarketProjects(projects))
});

class Market extends React.Component<
  WithStyles<typeof styles> & MarketProps,
  MarketState
> {
  constructor(props: WithStyles<typeof styles> & MarketProps) {
    super(props);
    this.state = {
      loading: props.marketProjects.length === 0
    };
  }

  public componentDidMount() {
    if (this.props.marketProjects.length === 0) {
      this.props.firebase
        .getByQuery('projects', 'public', '==', true)
        .then(data => {
          console.log(data);
          const projects: DisplayProject[] = data.map(proj => ({
            ...(proj as DisplayProject),
            cards: proj.projects[this.props.lang]
          }));

          this.props.loadMarketProjects(projects);
          this.setState({ loading: false });
        })
        .catch(err => console.error(err));
    }
  }

  public render() {
    if (this.state.loading) {
      return <LoadingIcon size={80} />;
    }

    const projects = this.props.marketProjects;
    return (
      <Grid container spacing={3} direction='row' justify='space-evenly'>
        {projects.map(proj => (
          <MarketCard key={proj.id} proj={proj} lang={this.props.lang} />
        ))}
      </Grid>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Market));
