import {
  BidState,
  BidActionTypes,
  LOAD_BIDS,
  ADD_BID,
  UPDATE_BID,
  DELETE_BID,
  CLEAR_BIDS
} from '../Store/Bid';

const initialState: BidState = {
  bids: []
};

export function bidReducer(
  state = initialState,
  action: BidActionTypes
): BidState {
  switch (action.type) {
    case LOAD_BIDS:
      return {
        bids: action.bids
      };
    case ADD_BID:
      return {
        bids: [...state.bids, action.bid]
      };
    case UPDATE_BID:
      return {
        bids: state.bids.map(bid =>
          bid.id === action.bid.id ? action.bid : bid
        )
      };
    case DELETE_BID:
      return {
        bids: state.bids.filter(bid => bid.id !== action.id)
      };
    case CLEAR_BIDS:
      return {
        bids: []
      };
    default:
      return state;
  }
}
