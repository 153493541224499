import React from 'react';
import ImagePlaceholder from '../../assets/placeholder.png';

interface ImageProps {
  original: string;
  processed?: string;
  alt: string;
  style: React.CSSProperties;
}

interface ImageState {
  src: string;
  errors: number;
}

export default class Image extends React.Component<ImageProps, ImageState> {
  constructor(props: ImageProps) {
    super(props);
    this.state = {
      src: props.processed
        ? props.processed.slice(0, props.processed.length - 3) + 'webp'
        : props.original,
      errors: 0
    };

    this.onError = this.onError.bind(this);
  }

  public onError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const { errors } = this.state;
    const newSrc =
      errors === 0
        ? this.props.processed
        : errors === 1
        ? this.props.original
        : ImagePlaceholder;
    this.setState({
      errors: errors + 1,
      src: newSrc
    });
  };

  public render() {
    return (
      <img
        onError={this.onError}
        src={this.state.src}
        alt={this.props.alt}
        style={this.props.style}
      />
    );
  }
}
