import React from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import MobileStepper from '@material-ui/core/MobileStepper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ImagePlaceholder from '../../assets/placeholder.png';
import {
  DisplayProject,
  PhotoUrl,
  CardType,
  RoomCard
} from '../../Redux/Store/Project';
import Chip from '@material-ui/core/Chip';
import { Trans } from '@lingui/macro';
import { CheckResponseStatus } from '../../Global';
import { extname } from 'path';
import Popover from '@material-ui/core/Popover';

interface ProjectCardProps {
  project: DisplayProject;
}

const ProjectCard: React.FunctionComponent<ProjectCardProps> = (
  props: ProjectCardProps
) => {
  const [coverCard, ...rest] = props.project.cards;
  const contactCard = props.project.contact;

  if (coverCard.type === CardType.PROJECT) {
    const startUrl =
      'https://storage.googleapis.com/manybuildprojects.appspot.com/' +
      props.project.id +
      '/';

    const photos = Object.keys(props.project.photos).map(k => ({
      hash: k,
      original: startUrl + props.project.photos[k],
      processed: startUrl + k + extname(props.project.photos[k])
    }));

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const [currPhotoUrl, setPhotoUrl] = React.useState<string>('');

    const handleClick = (ogUrl: string) => (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      setPhotoUrl(ogUrl);
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setPhotoUrl('');
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <div
        style={{
          margin: '0 auto',
          display: 'flex',
          boxShadow:
            '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12'
        }}
      >
        <Popover
          id={id}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {open &&
          currPhotoUrl.startsWith(
            'https://storage.googleapis.com/manybuildprojects.appspot.com/'
          ) ? (
            <img src={currPhotoUrl} alt='full size' />
          ) : null}
        </Popover>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '40%',
            padding: '2%'
          }}
        >
          <h3>{coverCard.name}</h3>
          <p>{contactCard.company}</p>
          <p>{props.project.projectType}</p>
          <p>{coverCard.description}</p>
          <Grid container spacing={1} style={{ paddingBottom: '5px' }}>
            {props.project.trades.length > 0 ? (
              props.project.trades.map(trade => (
                <Grid item key={trade.trade} style={{ textAlign: 'center' }}>
                  <Chip
                    label={trade.category}
                    style={{ margin: 'auto', overflow: 'visible' }}
                  />
                </Grid>
              ))
            ) : (
              <p style={{ color: '#0952aa' }}>
                <Trans>No trades listed</Trans>
              </p>
            )}
          </Grid>
        </div>
        {PictureDisplay(photos, false, coverCard.name, handleClick)}
      </div>
    );
  }
  throw Error;
};

interface RoomProps {
  rm: RoomCard;
}

const Room: React.FunctionComponent<RoomProps> = (props: RoomProps) => {
  const { rm } = props;
  // PictureDisplay(rm.photoUrls, true, rm.name)}
  return (
    <Grid item xs={12} md={8} lg={3} className='project-card'>
      <Card style={{ height: 600, marginTop: '15px' }}>
        <h4 style={{ textAlign: 'center' }}>{rm.name} Photos</h4>
        <CardContent style={{ height: 120 }} className='cardDescription'>
          <Typography
            variant='body2'
            color='textPrimary'
            component='div'
            style={{ height: 60 }}
          >
            <p>{rm.description}</p>
          </Typography>

          {rm.area && rm.area > 0 ? (
            <Typography variant='body2' color='textPrimary' component='div'>
              <p>Area: {rm.area} square feet</p>
            </Typography>
          ) : null}

          {rm.height && rm.height > 0 ? (
            <Typography variant='body2' color='textPrimary' component='div'>
              <p>Height: {rm.height} feet</p>
            </Typography>
          ) : null}
        </CardContent>
      </Card>
    </Grid>
  );
};

const PictureDisplay = (
  photos: PhotoUrl[],
  room: boolean,
  title: string,
  handleClick: (
    ogUrl: string
  ) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
) => {
  const maxSteps = photos.length;
  const [activeStep, setActiveStep] = React.useState(0);
  const [imgType, setImgType] = React.useState<'original' | 'processed'>(
    'processed'
  );

  if (maxSteps > 0) {
    CheckResponseStatus(photos[activeStep].processed)
      .then(res => {
        if (res) {
          setImgType('processed');
        } else {
          setImgType('original');
        }
      })
      .catch(err => console.log(err));
  }

  const handleChange = (forward: boolean) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (forward) {
      setActiveStep(activeStep + 1);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const img = maxSteps === 0 ? ImagePlaceholder : photos[activeStep][imgType];

  if (room) {
    return (
      <div
        style={{
          width: '100%'
        }}
      >
        <CardMedia
          component='div'
          image={img}
          title={title}
          style={{
            height: 300,
            width: '100%',
            objectFit: 'contain', // one of these two contains fixed the problem with scaling
            backgroundSize: 'contain' // I think this one but idk
          }}
        />
        <MobileStepper
          steps={maxSteps}
          position='static'
          variant='text'
          activeStep={activeStep}
          nextButton={
            <Button
              size='small'
              onClick={handleChange(true)}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size='small'
              onClick={handleChange(false)}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div
      style={{
        width: '60%'
      }}
    >
      <CardMedia
        component='div'
        image={img}
        title={title}
        style={{
          height: 850,
          objectFit: 'contain', // one of these two contains fixed the problem with scaling
          backgroundSize: 'contain' // I think this one but idk
        }}
        onClick={handleClick(photos[activeStep].original)}
      />
      <MobileStepper
        steps={maxSteps}
        position='static'
        variant='text'
        activeStep={activeStep}
        nextButton={
          <Button
            size='small'
            onClick={handleChange(true)}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size='small'
            onClick={handleChange(false)}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
    </div>
  );
};

export { PictureDisplay, Room };
export default ProjectCard;
