import React from 'react';
import { List, ListItem, IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingIcon from '../LoadingIcon';
import Image from '../Image';
import { PhotoUrl } from '../../Redux/Store/Project';

interface DragDropPreviewProps {
  photoUrls: PhotoUrl[];
  uploading: number;
  uploaded: boolean;
  handleUpdate: (files: File[]) => void;
  deletePic: (ind: number) => void;
}

/**  const mapChecks = (uploading: number) => {
  if (uploading < 1) {
    return null;
  }

  const checks = [];
  for (let i = 0; i < uploading; i++) {
    checks.push(
      <ListItem style={{ margin: 'auto' }} key={i + 25}>
        <CheckIcon style={{ margin: 'auto' }} />
      </ListItem>
    );
  }
  return checks;
}; */

export default class DragDropPreview extends React.Component<
  DragDropPreviewProps,
  { dragging: boolean }
> {
  public dragCounter = 0;
  constructor(props: DragDropPreviewProps) {
    super(props);

    this.state = {
      dragging: false
    };

    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  private handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };

  private handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    this.dragCounter--;
    if (this.dragCounter > 0) return;
    this.setState({
      dragging: false
    });
  };

  private handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  private handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ dragging: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleUpdate(
        Array.from(e.dataTransfer.files).filter(f =>
          /\.(jpe?g|png|pdf|webp)$/i.test(f.name) ? f : null
        )
      );
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  private handleDelete = (ind: number) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.props.deletePic(ind);
  };

  public render() {
    return (
      <div
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
      >
        <List>
          {this.props.photoUrls.map((url, ind) => (
            <ListItem style={{ margin: 'auto' }} key={ind}>
              <Image
                processed={url.processed}
                original={url.original}
                alt='Uploaded'
                style={{ width: '25%', margin: 'auto' }}
              />
              <IconButton
                style={{ float: 'right' }}
                color='secondary'
                onClick={this.handleDelete(ind)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
          {this.props.uploaded && this.props.uploading > 0 ? (
            <ListItem style={{ margin: 'auto' }}>
              <CheckIcon style={{ margin: 'auto' }} />
            </ListItem>
          ) : null}
          {this.props.uploading > 0 ? (
            <ListItem>
              <LoadingIcon size={60} />
            </ListItem>
          ) : null}
        </List>
      </div>
    );
  }
}
