import React from 'react';
import { FullBid } from '../../Redux/Store/Bid';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import Firebase from '../Firebase';
import LoadingIcon from '../LoadingIcon';
import {
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody
} from '@material-ui/core';

interface ProjectBidListProps {
  firebase: Firebase;
  projectId: string;
}

interface ProjectBidListState {
  bids: FullBid[];
  loading: boolean;
}

const BidListItem = (bid: FullBid) => (
  <TableRow key={bid.id}>
    <TableCell component='th' scope='row'>
      <Link to={'/bid/' + bid.id}>{bid.companyName}</Link>
    </TableCell>
    <TableCell align='right'>{bid.trades.join(', ')}</TableCell>
    <TableCell align='right'>${bid.bidAmt}</TableCell>
    <TableCell align='right'>
      {bid.materialIncluded ? <Trans>Yes</Trans> : <Trans>No</Trans>}
    </TableCell>
    <TableCell align='right'>
      {bid.approved ? <Trans>Yes</Trans> : <Trans>No</Trans>}
    </TableCell>
  </TableRow>
);

class ProjectBidList extends React.Component<
  ProjectBidListProps,
  ProjectBidListState
> {
  constructor(props: ProjectBidListProps) {
    super(props);
    this.state = {
      bids: [],
      loading: true
    };
  }

  public componentDidMount() {
    if (this.props.firebase.auth.currentUser) {
      this.props.firebase.firestore
        .collection('bids')
        .where('projectOwner', '==', this.props.firebase.auth.currentUser.uid)
        .where('projectId', '==', this.props.projectId)
        .get()
        .then(docsnap => {
          const bids: FullBid[] = [];
          docsnap.forEach(doc => {
            bids.push({ ...(doc.data() as FullBid), id: doc.id });
          });
          this.setState({
            bids,
            loading: false
          });
        })
        .catch(err => console.log(err));
    }
  }

  public render() {
    if (this.state.loading) {
      return <LoadingIcon size={80} />;
    }

    const { bids } = this.state;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Trans>Company Name</Trans>
            </TableCell>
            <TableCell align='right'>
              <Trans>Trades</Trans>
            </TableCell>
            <TableCell align='right'>
              <Trans>Bid Amount</Trans>
            </TableCell>
            <TableCell align='right'>
              <Trans>Material Included in Bid</Trans>
            </TableCell>
            <TableCell align='right'>
              <Trans>Approved</Trans>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{bids.map(bid => BidListItem(bid))}</TableBody>
      </Table>
    );
  }
}

export default ProjectBidList;
