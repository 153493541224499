import { Md5 } from 'ts-md5';
import url from 'url';
import { request } from 'http';

const GetBase64: (file: File) => Promise<string> = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      }
    };
    reader.onerror = error => reject(error);
  });

const GetHash = (hashee: string) => Md5.hashStr(hashee).toString();

const CheckResponseStatus: (target: string) => Promise<boolean> = (
  target: string
) =>
  new Promise((resolve, reject) => {
    let uri;
    try {
      uri = url.parse(target);
    } catch (err) {
      reject(new Error(`Invalid url ${target}`));
    }

    if (uri) {
      const options = {
        method: 'HEAD',
        host: uri.host,
        protocol: uri.protocol,
        port: uri.port,
        path: uri.path,
        timeout: 5 * 1000
      };

      const req = request(options, res => {
        const { statusCode } = res;

        if (statusCode && statusCode >= 200 && statusCode < 300) {
          resolve(true);
        } else {
          resolve(false);
        }
      });

      req.on('error', reject);
    } else {
      reject(new Error(`Invalid url ${target}`));
    }
  });

function DedupeArr<T>(arr: Array<T>): Array<T> {
  return [...new Set(arr)];
}

export { GetBase64, GetHash, CheckResponseStatus, DedupeArr };
