import React from 'react';
import TradeAutocomplete from '../Trades/TradeAutocomplete';
import Page from '../Page';
import { Trade } from '../Trades/NewTradeList';

const TypeToSearchDemo: React.FC = () => {
  const [trades, updateTrades] = React.useState<Trade[]>([]);
  return (
    <Page title='Type To Search Demo'>
      <TradeAutocomplete updateTrades={updateTrades} trades={trades} />
    </Page>
  );
};

export default TypeToSearchDemo;
