import {
  ProjectState,
  ProjectActionTypes,
  LOAD_USER_PROJECTS,
  ADD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  LOAD_MARKET_PROJECTS,
  UPDATE_MARKET_TYPE,
  CLEAR_USER_PROJECTS,
  UPDATE_LANGUAGE
} from '../Store/Project';

const initialState: ProjectState = {
  userProjects: [],
  marketProjects: [],
  market: [],
  lang: 'en'
};

export function projectReducer(
  state: ProjectState = initialState,
  action: ProjectActionTypes
): ProjectState {
  switch (action.type) {
    case LOAD_USER_PROJECTS:
      return {
        ...state,
        userProjects: action.projects
      };
    case CLEAR_USER_PROJECTS:
      return {
        ...state,
        userProjects: []
      };
    case ADD_PROJECT:
      return {
        ...state,
        userProjects: [...state.userProjects, action.project]
      };
    case UPDATE_PROJECT:
      return {
        ...state,
        userProjects: state.userProjects.map(proj =>
          proj.id === action.project.id ? action.project : proj
        ),
        marketProjects:
          action.project.public && action.project.approved
            ? state.marketProjects.map(proj =>
                proj.id === action.project.id
                  ? {
                      ...action.project,
                      cards: action.project.projects[state.lang]
                    }
                  : proj
              )
            : state.marketProjects.filter(proj => proj.id !== action.project.id)
      };
    case DELETE_PROJECT:
      return {
        ...state,
        userProjects: state.userProjects.filter(proj => proj.id !== action.id),
        marketProjects: state.marketProjects.filter(
          proj => proj.id !== action.id
        )
      };
    case LOAD_MARKET_PROJECTS:
      return {
        ...state,
        marketProjects: action.projects
      };
    case UPDATE_MARKET_TYPE:
      return {
        ...state,
        market: action.marketType,
        marketProjects: action.projects
      };
    case UPDATE_LANGUAGE:
      return {
        ...state,
        lang: action.lang
      };
    default:
      return state;
  }
}
