import React, { useContext } from 'react';
import { Trans, t } from '@lingui/macro';
import Button from '@material-ui/core/Button';
import Firebase, { FirebaseContext } from '../Firebase';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { History } from 'history';
import { RouteComponentProps } from 'react-router';
import Page from '../Page';
import FormItem from '../Form/FormItem';
import {
  validateNonEmpty,
  validateNewPassword,
  validateEmail,
  validatePhoneNum
} from '../Form/Validators';
import { FullUserData, DefaultUser } from '../../Redux/Store/User';
import { AddUser } from '../../Redux/Action/UserActions';
import { connect } from 'react-redux';
import { I18n } from '@lingui/react';
import { SupportedLang } from '../../Redux/Store/Project';
import { AppState } from '../../Redux/Reducer/RootReducer';

interface InitialForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNum: string;
  companyName: string;
  companyType: string;
  zipCode: string;
  passwordOne: string;
  passwordTwo: string;
  language: SupportedLang;
  error: string;
}

interface SignUpFormProps {
  firebase: Firebase;
  history: History;
  addUser: (user: FullUserData) => void;
  lang: SupportedLang;
}

type SignUpError =
  | 'auth/email-already-exists'
  | 'auth/invalid-email'
  | 'auth/uid-already-exists'
  | 'auth/phone-number-already-exists'
  | 'auth/invalid-password'
  | 'auth/invalid-display-name';

const ErrorMap: Record<SignUpError, string> = {
  'auth/email-already-exists': 'This email is already connected to an account.',
  'auth/invalid-email': 'Not a valid email, please double-check and try again.',
  'auth/uid-already-exists': 'Internal error, please refresh and try again.',
  'auth/phone-number-already-exists':
    'This phone number is already connected to an account.',
  'auth/invalid-display-name': 'The display name is empty.',
  'auth/invalid-password':
    'Invalid password, must be at least 6 characters in length.'
};

const mapDispatchToProps = (dispatch: any) => ({
  addUser: (user: FullUserData) => dispatch(AddUser(user))
});

const mapStateToProps = (state: AppState) => ({
  lang: state.Project.lang
});

class SignUpFormBase extends React.Component<SignUpFormProps, InitialForm> {
  constructor(props: SignUpFormProps) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNum: '',
      companyName: '',
      companyType: '',
      zipCode: '',
      passwordOne: '',
      passwordTwo: '',
      language: props.lang,
      error: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  public handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { firebase } = this.props;
    const { passwordOne, passwordTwo, error, ...newUser } = this.state;
    const fullName = newUser.firstName + ' ' + newUser.lastName;
    event.preventDefault();

    if (this.state.error.length > 0) {
      return;
    }

    firebase
      .doCreateUserWithEmailAndPassword(
        this.state.email,
        this.state.passwordOne
      )
      .then(user => {
        if (user.user) {
          firebase
            .addUser({ ...DefaultUser, ...newUser, fullName }, user.user.uid)
            .then(ref => {
              if (user.user && firebase.auth.currentUser) {
                this.props.addUser({
                  ...DefaultUser,
                  ...newUser,
                  fullName,
                  id: user.user.uid
                });

                firebase.auth.currentUser
                  .updateProfile({
                    displayName: fullName
                  })
                  .catch(err => this.setState({ error: err.message }));

                this.props.history.push('/');
              }
            })
            .catch(err => this.handleError(ErrorMap[err.code as SignUpError]));
        }
      })
      .catch(err => this.handleError(ErrorMap[err.code as SignUpError]));
  };

  public handleError = (error: string) => {
    this.setState({ error });
  };

  public handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | { name?: string | undefined; value: unknown }
    >
  ) => {
    this.setState({
      [event.target.name as keyof InitialForm]: event.target.value
    } as Pick<InitialForm, keyof InitialForm>);
  };

  public render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        style={{ width: '75%', margin: 'auto' }}
      >
        <I18n>
          {({ i18n }) => (
            <React.Fragment>
              <FormItem
                fieldId='firstNameField'
                value={this.state.firstName}
                name='firstName'
                label={i18n._(t`First Name`)}
                helper={i18n._(t`We will never share your information.`)}
                update={this.handleChange}
                handleError={this.handleError}
                validate={validateNonEmpty('first name')}
              />

              <FormItem
                fieldId='lastNameField'
                value={this.state.lastName}
                name='lastName'
                label={i18n._(t`Last Name`)}
                helper={i18n._(t`Required`)}
                update={this.handleChange}
                handleError={this.handleError}
                validate={validateNonEmpty('last name')}
              />

              <FormItem
                fieldId='emailField'
                value={this.state.email}
                name='email'
                type='email'
                label={i18n._(t`Email Address`)}
                helper={i18n._(t`Required`)}
                update={this.handleChange}
                handleError={this.handleError}
                validate={validateEmail}
              />
              <FormItem
                fieldId='phoneField'
                name='phoneNum'
                type='tel'
                label={i18n._(t`Telephone Number`)}
                helper={i18n._(t`Please include area code.`)}
                value={this.state.phoneNum}
                update={this.handleChange}
                handleError={this.handleError}
                validate={validatePhoneNum}
              />

              <FormItem
                fieldId='companyNameField'
                name='companyName'
                label={i18n._(t`Company Name`)}
                helper={i18n._(t`Required`)}
                value={this.state.companyName}
                update={this.handleChange}
                handleError={this.handleError}
                validate={validateNonEmpty('company name')}
              />

              <FormItem
                fieldId='companyTypeField'
                name='companyType'
                label={i18n._(t`Company Type`)}
                helper={i18n._(t`Your company's trade.`)}
                value={this.state.companyType}
                update={this.handleChange}
                handleError={this.handleError}
                validate={validateNonEmpty('company type')}
              />

              <FormControl fullWidth style={{ marginTop: '15px' }}>
                <InputLabel htmlFor='lang'>
                  <Trans>Language</Trans>
                </InputLabel>
                <Select
                  value={this.state.language}
                  onChange={this.handleChange}
                  name='language'
                  inputProps={{ id: 'lang', name: 'language' }}
                >
                  <MenuItem value={'es'}>Español</MenuItem>
                  <MenuItem value={'en'}>English</MenuItem>
                </Select>
              </FormControl>
              <FormItem
                fieldId='zipCodeField'
                name='zipCode'
                label={i18n._(t`Zip Code`)}
                helper={i18n._(t`Please enter your zip code.`)}
                value={this.state.zipCode}
                update={this.handleChange}
                handleError={this.handleError}
                validate={(val: string) => {
                  if (val.length < 5) {
                    return 'Please enter a full zip code';
                  }
                  return 'OK';
                }}
              />

              <FormItem
                fieldId='passwordOneField'
                name='passwordOne'
                label={i18n._(t`Password`)}
                type='password'
                helper={i18n._(
                  t`10+ characters, including a capital letter and a number.`
                )}
                value={this.state.passwordOne}
                update={this.handleChange}
                handleError={this.handleError}
                validate={validateNewPassword}
              />

              <FormItem
                fieldId='passwordTwoField'
                name='passwordTwo'
                label={i18n._(t`Confirm Password`)}
                type='password'
                helper=''
                value={this.state.passwordTwo}
                update={this.handleChange}
                handleError={this.handleError}
                validate={(val: string) => {
                  if (
                    val.length !== this.state.passwordOne.length ||
                    val !== this.state.passwordTwo
                  ) {
                    return 'Does not match first password.';
                  }
                  return 'OK';
                }}
              />

              {this.state.error.length > 0 ? (
                <p style={{ color: 'red' }}>{this.state.error}</p>
              ) : null}

              <FormControl fullWidth style={{ marginTop: '15px' }}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  style={{ margin: 'auto' }}
                  disabled={this.state.error.length > 0}
                >
                  <Trans>Register</Trans>
                </Button>
              </FormControl>
            </React.Fragment>
          )}
        </I18n>
      </form>
    );
  }
}

const SignUpForm = connect(mapStateToProps, mapDispatchToProps)(SignUpFormBase);
const SignUpPage: React.FC<RouteComponentProps> = props => {
  const fb = useContext(FirebaseContext);
  return (
    <I18n>
      {({ i18n }) => (
        <Page title={i18n._(t`Register`)}>
          <SignUpForm firebase={fb} history={props.history} />
        </Page>
      )}
    </I18n>
  );
};

export default SignUpPage;
