import React from 'react';
import FormItem from '../Form/FormItem';
import { validateNonEmpty } from '../Form/Validators';
import { ProjCard, CardType, SupportedLang } from '../../Redux/Store/Project';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { I18n } from '@lingui/react';
import { t } from '@lingui/macro';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

interface CardProps {
  card: ProjCard;
  projectId: string;
  lang: SupportedLang;
  updateCard: (card: ProjCard) => void;
  deleteCard: () => void;
  moveCard: (ind: number, newInd: number) => void;
  handleSelectCardType: (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => void;
  handleError: (error: string) => void;
}

export default class CardForm extends React.Component<CardProps> {
  public shouldComponentUpdate(nextProps: CardProps) {
    return true;
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const { card } = this.props;

    switch (card.type) {
      case CardType.CONTACT:
        this.props.updateCard({
          ...card,
          [name]: value
        });
        break;
      case CardType.IFRAME:
        this.props.updateCard({
          ...card,
          [name]: value
        });
        break;
      case CardType.PROJECT:
        this.props.updateCard({
          ...card,
          [name]: value
        });
        break;
      case CardType.YOUTUBE:
        this.props.updateCard({
          ...card,
          [name]: value
        });
        break;
      case CardType.ROOM:
        this.props.updateCard({
          ...card,
          [name]: value
        });
    }
  };

  public render() {
    const { card } = this.props;
    switch (card.type) {
      case CardType.BLANK:
        return (
          <FormControl fullWidth style={{ marginTop: '15px' }}>
            <InputLabel shrink htmlFor='newCardTypeField'>
              Card Type
            </InputLabel>
            <Select
              value=''
              onChange={this.props.handleSelectCardType}
              input={<Input name='cardType' id='newCardTypeField' />}
              name='cardType'
            >
              <MenuItem value={CardType.ROOM}>Room</MenuItem>
              <MenuItem value={CardType.IFRAME}>IFrame</MenuItem>
              <MenuItem value={CardType.YOUTUBE}>Video</MenuItem>
            </Select>
          </FormControl>
        );
      case CardType.CONTACT:
        return (
          <I18n>
            {({ i18n }) => (
              <React.Fragment>
                <FormItem
                  name='name'
                  fieldId={this.props.lang + 'ContactFormName'}
                  label={i18n._(t`Project Owner/Manager`)}
                  helper={i18n._(
                    t`Please enter the name of the project owner.`
                  )}
                  value={card.name}
                  update={this.handleChange}
                  validate={validateNonEmpty(i18n._(t`Project Owner/Manager`))}
                  handleError={this.props.handleError}
                />
                <FormItem
                  name='company'
                  fieldId={this.props.lang + 'ContactFormCompany'}
                  label={i18n._(t`Company Name`)}
                  helper={i18n._(t`Please enter the name of the company.`)}
                  value={card.phoneNum}
                  update={this.handleChange}
                  validate={validateNonEmpty(i18n._(t`Company Name`))}
                  handleError={this.props.handleError}
                />
                <FormItem
                  name='phoneNum'
                  fieldId={this.props.lang + 'ContactFormPhone'}
                  label={i18n._(t`Phone Number`)}
                  helper={i18n._(t`Please enter the contact phone number.`)}
                  value={card.phoneNum}
                  update={this.handleChange}
                  validate={validateNonEmpty(i18n._(t`Contact Phone Number`))}
                  handleError={this.props.handleError}
                />
                <FormItem
                  name='email'
                  fieldId={this.props.lang + 'ContactFormEmail'}
                  label={i18n._(t`Contact Email`)}
                  helper={i18n._(t`Please enter the contact email.`)}
                  value={card.phoneNum}
                  update={this.handleChange}
                  validate={validateNonEmpty(i18n._(t`Contact Email`))}
                  handleError={this.props.handleError}
                />
              </React.Fragment>
            )}
          </I18n>
        );
      case CardType.IFRAME:
        return (
          <I18n>
            {({ i18n }) => (
              <React.Fragment>
                <FormItem
                  name='url'
                  fieldId={this.props.lang + 'IFrameFormURL'}
                  label={i18n._(t`IFrame`)}
                  helper={i18n._(t`Enter the URL for the IFrame.`)}
                  value={card.url}
                  update={this.handleChange}
                  validate={validateNonEmpty(i18n._(t`IFrame URL`))}
                  handleError={this.props.handleError}
                />
                <FormControl fullWidth style={{ marginTop: '15px' }}>
                  <IconButton
                    style={{ margin: 'auto' }}
                    color='secondary'
                    onClick={this.props.deleteCard}
                  >
                    <DeleteIcon />
                  </IconButton>
                </FormControl>
              </React.Fragment>
            )}
          </I18n>
        );
      case CardType.PROJECT:
        return (
          <I18n>
            {({ i18n }) => (
              <React.Fragment>
                <FormItem
                  name='name'
                  fieldId={this.props.lang + 'ProjectFormName'}
                  label={i18n._(t`Project Name`)}
                  helper=''
                  value={card.name}
                  update={this.handleChange}
                  validate={validateNonEmpty(i18n._(t`Project Name`))}
                  handleError={this.props.handleError}
                />
                <FormItem
                  name='description'
                  label={i18n._(t`Project Description`)}
                  fieldId={this.props.lang + 'ProjectFormDescription'}
                  helper=''
                  rows='4'
                  multiline
                  value={card.description}
                  update={this.handleChange}
                  validate={validateNonEmpty(i18n._(t`Project Description`))}
                  handleError={this.props.handleError}
                />
              </React.Fragment>
            )}
          </I18n>
        );
      case CardType.YOUTUBE:
        return (
          <I18n>
            {({ i18n }) => (
              <React.Fragment>
                <FormItem
                  name='title'
                  fieldId={this.props.lang + 'VideoFormTitle'}
                  label={i18n._(t`Title`)}
                  helper=''
                  value={card.title}
                  update={this.handleChange}
                  validate={validateNonEmpty(i18n._(t`Video Title`))}
                  handleError={this.props.handleError}
                />
                <FormItem
                  name='url'
                  fieldId={this.props.lang + 'VideoFormURL'}
                  label={i18n._(t`URL`)}
                  helper={i18n._(t`Enter the URL for the video.`)}
                  value={card.url}
                  update={this.handleChange}
                  validate={validateNonEmpty(i18n._(t`Video URL`))}
                  handleError={this.props.handleError}
                />
                <FormControl fullWidth style={{ marginTop: '15px' }}>
                  <IconButton
                    style={{ margin: 'auto' }}
                    color='secondary'
                    onClick={this.props.deleteCard}
                  >
                    <DeleteIcon />
                  </IconButton>
                </FormControl>
              </React.Fragment>
            )}
          </I18n>
        );
      case CardType.ROOM:
        return (
          <I18n>
            {({ i18n }) => (
              <React.Fragment>
                <FormItem
                  name='name'
                  fieldId={this.props.lang + 'RoomFormName'}
                  label={i18n._(t`Room Name`)}
                  helper={i18n._(t`Enter the room name`)}
                  value={card.name}
                  update={this.handleChange}
                  validate={validateNonEmpty(i18n._(t`Room Name`))}
                  handleError={this.props.handleError}
                />
                <FormItem
                  name='description'
                  label={i18n._(t`Room Description`)}
                  fieldId={this.props.lang + 'RoomFormDescription'}
                  helper={i18n._(t`Please describe the work required`)}
                  rows='4'
                  multiline
                  value={card.description}
                  update={this.handleChange}
                  validate={validateNonEmpty(i18n._(t`Room Description`))}
                  handleError={this.props.handleError}
                />
                <FormItem
                  name='height'
                  label={i18n._(t`Room Height`)}
                  fieldId={this.props.lang + 'RoomFormHeight'}
                  type='number'
                  helper={i18n._(t`Feet`)}
                  value={card.height.toString()}
                  update={this.handleChange}
                  validate={validateNonEmpty(i18n._(t`Room Height`))}
                  handleError={this.props.handleError}
                />
                <FormItem
                  name='area'
                  label={i18n._(t`Room Area`)}
                  fieldId={this.props.lang + 'RoomFormArea'}
                  type='number'
                  helper={i18n._(t`Square Feet`)}
                  value={card.area.toString()}
                  update={this.handleChange}
                  validate={validateNonEmpty(i18n._(t`Room Area`))}
                  handleError={this.props.handleError}
                />
                <FormControl fullWidth style={{ marginTop: '15px' }}>
                  <IconButton
                    style={{ margin: 'auto' }}
                    color='secondary'
                    onClick={this.props.deleteCard}
                  >
                    <DeleteIcon />
                  </IconButton>
                </FormControl>
              </React.Fragment>
            )}
          </I18n>
        );
    }
  }
}
