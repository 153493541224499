import React from 'react';

const Currency: React.FC<{ val: number; curr?: string; cents?: boolean }> = (props: {
  val: number;
  curr?: string;
  cents?: boolean;
}) => (
  <span>
    {props.val.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    })}
  </span>
);

export default Currency;
