import React from 'react';
import {
  ProjCard,
  SupportedUserLang,
  ProjectLangs,
  CardType,
  DefaultRoomCard,
  DefaultIframeCard,
  DefaultYoutubeCard,
  SupportedLang
} from '../../Redux/Store/Project';
import { Grid } from '@material-ui/core';
import CardForm from './CardForm';

interface LangColumnProps {
  projects: ProjectLangs;
  updateProjects: (projects: ProjectLangs) => void;
  currentLang: SupportedUserLang;
  id: string;
}

export default class LangColumn extends React.Component<
  LangColumnProps,
  ProjectLangs
> {
  constructor(props: LangColumnProps) {
    super(props);
    this.state = props.projects;
  }

  componentDidUpdate() {
    if (this.state.en[this.state.en.length - 1].type !== CardType.BLANK)
      this.setState(
        state => ({
          en: [...state.en, { type: CardType.BLANK }],
          es: [...state.es, { type: CardType.BLANK }]
        }),
        () => this.props.updateProjects(this.state)
      );
  }

  private handleSelectCardType = (ind: number) => (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const defCard =
      event.target.value === CardType.ROOM
        ? DefaultRoomCard
        : event.target.value === CardType.IFRAME
        ? DefaultIframeCard
        : DefaultYoutubeCard;

    this.setState(
      state => ({
        en: state.en.map((c, i) => (i === ind ? defCard : c)),
        es: state.es.map((c, i) => (i === ind ? defCard : c))
      }),
      () => this.props.updateProjects(this.state)
    );
  };

  private updateCard = (ind: number, lang: SupportedLang) => (
    card: ProjCard
  ) => {
    this.setState(
      state => ({
        ...state,
        [lang]: state[lang].map((c, i) => (i === ind ? card : c))
      }),
      () => this.props.updateProjects(this.state)
    );
  };

  private deleteCard = (ind: number) => () => {
    this.setState(
      state => ({
        en: state.en.filter((c, i) => i !== ind),
        es: state.es.filter((c, i) => i !== ind)
      }),
      () => this.props.updateProjects(this.state)
    );
  };

  private moveCard = () => {};

  render() {
    const { currentLang, id } = this.props;
    if (Array.isArray(currentLang)) {
      const cards = currentLang.map(lang => (
        <Grid key={lang + 'CardFormGridItem'} item xs={12} md={6} lg={6}>
          <Grid container spacing={3} direction='row' justify='space-evenly'>
            {this.state[lang].map((card, ind) => (
              <Grid key={lang + 'CardForm' + ind} item xs={12} md={6} lg={6}>
                <div className='newProjectFormType'>
                  <CardForm
                    lang={lang}
                    card={card}
                    projectId={id}
                    deleteCard={this.deleteCard(ind)}
                    updateCard={this.updateCard(ind, lang)}
                    handleSelectCardType={this.handleSelectCardType(ind)}
                    moveCard={this.moveCard}
                    handleError={error => {}}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ));
      return (
        <Grid container spacing={3} direction='row' justify='space-evenly'>
          {cards}
        </Grid>
      );
    } else {
      const cards = this.state[currentLang].map((card, ind) => (
        <Grid key={currentLang + 'CardForm' + ind} item xs={12} md={6} lg={6}>
          <div className='newProjectFormType'>
            <CardForm
              lang={currentLang}
              card={card}
              projectId={id}
              deleteCard={this.deleteCard(ind)}
              updateCard={this.updateCard(ind, currentLang)}
              handleSelectCardType={this.handleSelectCardType(ind)}
              moveCard={this.moveCard}
              handleError={error => {}}
            />
          </div>
        </Grid>
      ));
      return (
        <Grid container spacing={3} direction='row' justify='space-evenly'>
          {cards}
        </Grid>
      );
    }
  }
}
