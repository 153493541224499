import { Trade } from '../../Components/Trades/NewTradeList';

export interface Bid {
  firstName: string;
  lastName: string;
  email: string;
  phoneNum: string;
  companyName: string;
  materialIncluded: boolean;
  bidAmt: number;
  trades: Trade[];
  message: string;
}

export const DefaultNewBid: Bid = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNum: '',
  companyName: '',
  materialIncluded: false,
  bidAmt: 0,
  trades: [],
  message: ''
};

export interface FullBid extends Bid {
  id: string;
  created: string;
  projectId: string;
  projectOwner: string;
  projectName: string;
  ownerId: string;
  approved: boolean;
}

export const DefaultBid: FullBid = {
  id: '',
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  phoneNum: '',
  materialIncluded: false,
  bidAmt: 0,
  trades: [],
  message: '',
  created: '',
  projectId: '',
  projectOwner: '',
  projectName: '',
  ownerId: '',
  approved: false
};

export interface BidState {
  bids: FullBid[];
}

export const LOAD_BIDS = 'LOAD_BIDS';
export const ADD_BID = 'ADD_BID';
export const UPDATE_BID = 'UPDATE_BID';
export const DELETE_BID = 'DELETE_BID';
export const CLEAR_BIDS = 'CLEAR_BIDS';

interface LoadBidAction {
  type: typeof LOAD_BIDS;
  bids: FullBid[];
}

interface AddBidAction {
  type: typeof ADD_BID;
  bid: FullBid;
}

interface UpdateBidAction {
  type: typeof UPDATE_BID;
  bid: FullBid;
}

interface DeleteBidAction {
  type: typeof DELETE_BID;
  id: string;
}

interface ClearBidAction {
  type: typeof CLEAR_BIDS;
}

export type BidActionTypes =
  | LoadBidAction
  | AddBidAction
  | UpdateBidAction
  | DeleteBidAction
  | ClearBidAction;
