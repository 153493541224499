import React, { useContext, useState, SetStateAction, Dispatch } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { History } from 'history';
import { Trans } from '@lingui/macro';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Firebase, { FirebaseContext } from '../Firebase';
import { FullUserData } from '../../Redux/Store/User';
import { AppState } from '../../Redux/Reducer/RootReducer';
import Page from '../Page';
import {
  FullProject,
  DisplayProject,
  SupportedLang,
  CardType,
  CoverCard,
  RoomCard,
  DefaultDisplayProject
} from '../../Redux/Store/Project';
import LoadingIcon from '../LoadingIcon';
import { BidForm, ProjectBidList } from '../Bid';
import Mixpanel from '../Mixpanel';
import ProjectCard, { Room } from './ProjectCard';

interface ProjectRouteProps {
  projectId: string;
}

interface ProjectDisplayProps {
  firebase: Firebase;
  history: History;
  projectId: string;
  updateTitle: (title: string) => void;
  user: FullUserData;
  lang: SupportedLang;
  marketProjects: DisplayProject[];
}

interface ProjectDisplayState {
  project: DisplayProject;
  downloadUrl: string;
  interested: boolean;
  owner: boolean;
  loading: boolean;
}

class ProjectDisplayBase extends React.Component<
  ProjectDisplayProps,
  ProjectDisplayState
> {
  constructor(props: ProjectDisplayProps) {
    super(props);
    const project = props.marketProjects.filter(
      proj => props.projectId === proj.id
    );

    this.state = {
      downloadUrl: '',
      interested: false,
      owner: false,
      project: project.length === 1 ? project[0] : DefaultDisplayProject,
      loading: true
    };

    this.handleInterested = this.handleInterested.bind(this);
  }

  public componentDidMount() {
    if (this.state.project.id === 'AA000DEN0000') {
      this.getProjectById();
    } else if (this.props.user) {
      this.getDownloadUrl();
    }
  }

  public shouldComponentUpdate(
    nextProps: ProjectDisplayProps,
    nextState: ProjectDisplayState
  ) {
    return (
      nextProps.lang !== this.props.lang ||
      nextProps.user.id !== this.props.user.id ||
      nextProps.projectId !== this.props.projectId ||
      nextState.interested !== this.state.interested ||
      nextState.owner !== this.state.owner ||
      nextState.project.id !== this.state.project.id ||
      nextState.loading !== this.state.loading
    );
  }

  private handleDelete = (id: string) => {
    const sure = window.confirm(
      'Are you sure you would like to delete this project?'
    );
    if (sure) {
      this.props.firebase.firestore
        .collection('projects')
        .doc(id)
        .get()
        .then(snap => {
          snap.ref
            .delete()
            .then(() => {
              this.props.firebase.firestore
                .collection('bids')
                .where('projectId', '==', id)
                .get()
                .then(docsnap => {
                  docsnap.forEach(qsnap => {
                    qsnap.ref.delete().catch(err => console.log(err));
                  });
                })
                .catch(err => console.log(err));
              alert('Project deleted.');
            })
            .catch(err => console.log(err));
        })
        .catch(err => console.log(err));
    }
  };

  private getProjectById = () => {
    this.props.firebase
      .getByQuery('projects', 'id', '==', this.props.projectId)
      .then(projs => {
        if (projs.length === 1) {
          const proj = projs[0] as FullProject;
          const disProj: DisplayProject = {
            ...proj,
            cards: proj.projects[this.props.lang]
          };

          if (this.props.user) {
            this.setState(
              {
                project: disProj
              },
              this.getDownloadUrl
            );
          } else {
            this.setState({
              project: disProj,
              loading: false
            });
          }
        } else {
          throw Error;
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false
        });
      });
  };

  private getDownloadUrl = () => {
    const owner = this.props.user.id === this.state.project.ownerId;
    this.props.firebase.storage
      .ref(
        this.state.project.firebaseId +
          '/webassets/' +
          this.state.project.id +
          '-documents.pdf'
      )
      .getDownloadURL()
      .then(url => {
        const downloadUrl = typeof url === 'string' ? url : '';
        this.setState({
          loading: false,
          downloadUrl,
          owner
        });
      })
      .catch(err => {
        if (err.code !== 'storage/object-not-found') console.log(err);
        this.setState({
          loading: false,
          owner
        });
      });
  };

  private handleInterested = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (
      this.props.user.id &&
      this.props.user.id.length > 0 &&
      this.props.user.paid
    ) {
      Mixpanel.track('New bid start', {
        uid: this.props.user.id,
        projectId: this.props.projectId
      });
      this.setState({
        interested: true
      });
    } else {
      this.props.history.push('/sign-up');
    }
  };

  public render() {
    if (this.state.project.id === 'AA000DEN0000' || !this.props.user) {
      return <LoadingIcon size={80} />;
    }

    const [coverCard, ...rest] = this.state.project.cards;

    const name = (coverCard as CoverCard).name;

    const roomCards = rest
      .filter((rm): rm is RoomCard => rm.type === CardType.ROOM)
      .map(rm => <Room rm={rm} key={rm.name} />);

    if (this.props.user.paid) {
      return (
        <React.Fragment>
          <ProjectCard project={this.state.project} />
          {this.state.downloadUrl.length > 0 ? (
            <div style={{ width: '100%', margin: 'auto' }}>
              <Button
                variant='contained'
                style={{
                  display: 'block',
                  margin: 'auto',
                  width: '10%',
                  textAlign: 'center',
                  marginTop: '15px',
                  backgroundColor: '#fbb200'
                }}
                component='a'
                href={this.state.downloadUrl}
              >
                Download Photos
              </Button>
            </div>
          ) : null}
          <Card style={{ padding: 25, marginTop: 20 }}>
            <Grid container spacing={3} direction='row' justify='space-evenly'>
              {roomCards}
            </Grid>
          </Card>
          {this.state.loading ? <LoadingIcon size={60} /> : null}
          {this.state.owner || this.props.user.admin ? (
            <React.Fragment>
              <h3 style={{ textAlign: 'center' }}>
                <Link to={'/edit-project/' + this.props.projectId}>
                  <Trans>Edit</Trans>
                </Link>
              </h3>
              <h3 style={{ textAlign: 'center' }}>
                <Trans>Bids</Trans>
              </h3>
              <ProjectBidList
                firebase={this.props.firebase}
                projectId={this.props.projectId}
              />
              <IconButton
                aria-label='Delete'
                type='button'
                color='secondary'
                style={{ margin: 'auto', display: 'block' }}
                onClick={event => {
                  this.handleDelete(this.props.projectId);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </React.Fragment>
          ) : null}
          {this.state.interested ? (
            <div style={{ width: '60%', margin: 'auto' }}>
              <BidForm
                closeModal={() => this.setState({ interested: false })}
                firebase={this.props.firebase}
                history={this.props.history}
                projectId={this.props.projectId}
                projectOwner={this.state.project.ownerId}
                projectName={name}
                user={this.props.user}
              />
            </div>
          ) : (
            <div style={{ width: '100%', marginTop: '25px' }}>
              <Button
                variant='contained'
                onClick={this.handleInterested}
                style={{
                  margin: 'auto',
                  display: 'block',
                  backgroundColor: '#fbb200'
                }}
              >
                <Trans>I'm Interested</Trans>
              </Button>
            </div>
          )}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <ProjectCard project={this.state.project} />
        <Card style={{ padding: 25, marginTop: 20 }}>
          <Grid container spacing={3} direction='row' justify='space-evenly'>
            {roomCards}
          </Grid>
        </Card>
        {this.props.user.id.length > 0 ? (
          <div style={{ width: '100%', marginTop: '25px' }}>
            <Button
              variant='contained'
              onClick={this.handleInterested}
              style={{ margin: 'auto', display: 'block' }}
            >
              <Trans>Register</Trans>
            </Button>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.Auth.user,
  lang: state.Project.lang,
  marketProjects: state.Project.marketProjects
});

const ProjectDisplay = (connect(
  mapStateToProps,
  null
)(ProjectDisplayBase) as unknown) as React.ComponentType<{
  firebase: Firebase;
  projectId: string;
  updateTitle: Dispatch<SetStateAction<string>>;
  history: History<any>;
}>;

const ProjectPage: React.FC<RouteComponentProps<ProjectRouteProps>> = (
  props: RouteComponentProps<ProjectRouteProps>
) => {
  const firebase = useContext(FirebaseContext);
  const projectId = props.match.params.projectId;
  const [title, updateTitle] = useState('');

  return (
    <Page title={title}>
      <ProjectDisplay
        firebase={firebase}
        projectId={projectId}
        updateTitle={updateTitle}
        history={props.history}
      />
    </Page>
  );
};

export default ProjectPage;
