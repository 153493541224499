import React from 'react';
import { Switch, Route } from 'react-router-dom';
import UserPage, { SignInPage, SignUpPage } from '../User';
import HomePage from '../Home';
import NewProjectPage, { ProjectPage } from '../Project';
import DetailBidPage from '../Bid';
import EditProjectPage from '../Project/ProjectForm';
import PrivateRoute from './PrivateRoute';
import AdminPage from '../Admin';
import EmailSignInPage from '../User/EmailSignIn';
import SetPasswordPage, { ChangePasswordPage } from '../User/SetPassword';
import TypeToSearchDemo from '../Demos/TypeToSearch';
import NewProjectIFrame from '../Project/NewProjectIFrame';
import { ConnectedComponent } from 'react-redux';

interface RoutesProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
}

const DefaultProtectedRoutesProps = {
  authenticationPath: '/sign-in',
  isAllowed: true,
  restrictedPath: '/'
};

const PrivateRoutes = [
  '/new-project',
  '/new-project-iframe',
  '/edit-project/:projectId',
  '/bid/:bidId',
  '/user',
  '/admin/panel',
  '/change-password',
  'set-password'
];

const PrivateRouteComponents: Record<
  string,
  React.ComponentType<any> | ConnectedComponent<any, any>
> = {
  '/new-project': NewProjectPage,
  '/new-project-iframe': NewProjectIFrame,
  '/edit-project/:projectId': EditProjectPage,
  '/bid/:bidId': DetailBidPage,
  '/user': UserPage,
  '/admin/panel': AdminPage,
  '/change-password': ChangePasswordPage,
  '/set-password': SetPasswordPage
};

export default (props: RoutesProps) => (
  <Switch>
    <Route exact path='/' component={HomePage} />
    <Route path='/sign-in' component={SignInPage} />
    <Route path='/sign-up' component={SignUpPage} />
    <Route path='/email-sign-in/' component={EmailSignInPage} />
    <Route path='/first-sign-in-email/' component={EmailSignInPage} />
    <Route path='/project/:projectId' component={ProjectPage} />
    <Route path='/typetosearch-demo' component={TypeToSearchDemo} />
    {PrivateRoutes.map(r => (
      <PrivateRoute
        key={r}
        path={r}
        component={PrivateRouteComponents[r]}
        isAuthenticated={props.isAuthenticated}
        {...DefaultProtectedRoutesProps}
      />
    ))}
  </Switch>
);
