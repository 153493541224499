import React, { ReactNode } from 'react';
import TopNav from './TopNav';
import Footer from './Footer';
import { HeaderContainer, BodyContainer } from '../Container';
import './Page.css';
import Mixpanel from '../Mixpanel';
import ErrorBoundary from '../Errors/ErrorBoundary';

const Page: React.FC<{ title: string }> = (props: {
  children?: ReactNode;
  title?: string;
}) => {
  // track referrer from here; if document.referrer contains 'manybuild', ignore it
  Mixpanel.init();
  if (props.title) {
    Mixpanel.track('Page view', { title: props.title });
  return (
    <ErrorBoundary>
      <TopNav />
      <div id='content_container'>
        <HeaderContainer title={props.title} />
        <BodyContainer>{props.children}</BodyContainer>
      </div>
      <Footer />
    </ErrorBoundary>
  );
  } else {
  return (
    <ErrorBoundary>
      <TopNav />
      <div id='content_container'>
        <BodyContainer>{props.children}</BodyContainer>
      </div>
      <Footer />
    </ErrorBoundary>
  );
  }
};

export default Page;
