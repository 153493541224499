import React, { useContext } from 'react';
import { I18n } from '@lingui/react';
import { t } from '@lingui/macro';
import Page from '../Page';
import Market from '../Market/Market';
import { FirebaseContext } from '../Firebase';
import { UserState } from '../../Redux/Store/User';
import { AppState } from '../../Redux/Reducer/RootReducer';
import { connect } from 'react-redux';

const mapStateToProps = (state: AppState) => ({
  user: state.Auth.user
});
const HomePage: React.FC<UserState> = (props: UserState) => {
  const firebase = useContext(FirebaseContext);
  return (
    <I18n>
      {({ i18n }) => (
        <Page title={i18n._(t`Explore Projects`)}>
          <Market firebase={firebase} />
        </Page>
      )}
    </I18n>
  );
};

export default connect(mapStateToProps, null)(HomePage);
