import { Trade } from '../../Components/Trades/NewTradeList';
export type SupportedLang = 'en' | 'es';
export const SupportedLangs: SupportedLang[] = ['en', 'es'];

export type SupportedUserLang = SupportedLang | SupportedLang[];

export interface PhotoUrl {
  hash: string;
  original: string;
  processed: string;
}

export enum CardType {
  YOUTUBE,
  ROOM,
  IFRAME,
  CONTACT,
  PROJECT,
  BLANK
}

export interface YoutubeCard {
  type: CardType.YOUTUBE;
  url: string;
  title: string;
}

export const DefaultYoutubeCard: YoutubeCard = {
  type: CardType.YOUTUBE,
  url: '',
  title: 'Video Walkthrough'
};

export interface RoomCard {
  type: CardType.ROOM;
  height: number;
  area: number;
  description: string;
  name: string;
}

export const DefaultRoomCard: RoomCard = {
  type: CardType.ROOM,
  height: 0,
  area: 0,
  description: '',
  name: ''
};

export interface IframeCard {
  type: CardType.IFRAME;
  url: string;
}

export const DefaultIframeCard: IframeCard = {
  type: CardType.IFRAME,
  url: ''
};

export interface ContactCard {
  type: CardType.CONTACT;
  phoneNum: string;
  email: string;
  name: string;
  company: string;
}

export const DefaultContactCard: ContactCard = {
  type: CardType.CONTACT,
  phoneNum: '',
  email: '',
  name: '',
  company: ''
};

export interface CoverCard {
  type: CardType.PROJECT;
  name: string;
  description: string;
}

export const DefaultCoverCard: CoverCard = {
  type: CardType.PROJECT,
  name: '',
  description: ''
};

export interface BlankCard {
  type: CardType.BLANK;
}

export const DefaultBlankCard: BlankCard = {
  type: CardType.BLANK
};

export type ProjCard =
  | RoomCard
  | YoutubeCard
  | IframeCard
  | ContactCard
  | CoverCard
  | BlankCard;

export interface PhotoAlbum {
  [key: string]: string;
}

export type PhotoHash = keyof PhotoAlbum & string;

export interface ProjectMetadata {
  id: string;
  firebaseId: string;
  created: string;
  ownerId: string;
  contact: ContactCard;
  public: boolean;
  approved: boolean;
  awarded: boolean;
  pdfDownload: boolean;
  awardValue: number;
  coverPhoto: PhotoHash;
  photos: PhotoAlbum;
  trades: Trade[];
  status: ProjectStatus;
  projectType: ConstructionType;
}

export const DefaultProjectMetadata: ProjectMetadata = {
  id: 'AA000DEN0000',
  firebaseId: '',
  created: '',
  ownerId: '',
  contact: DefaultContactCard,
  public: false,
  approved: false,
  awarded: false,
  pdfDownload: false,
  awardValue: 0,
  coverPhoto: '',
  photos: {},
  trades: [],
  status: 'Draft',
  projectType: 'New Construction Residential'
};

export type ProjectLangs = Record<SupportedLang, ProjCard[]>;

export interface FullProject extends ProjectMetadata {
  projects: ProjectLangs;
}

export const DefaultFullProject: FullProject = {
  ...DefaultProjectMetadata,
  projects: {
    en: [DefaultCoverCard, DefaultBlankCard],
    es: [DefaultCoverCard, DefaultBlankCard]
  }
};

export interface DisplayProject extends ProjectMetadata {
  cards: ProjCard[];
}

export const DefaultDisplayProject: DisplayProject = {
  ...DefaultProjectMetadata,
  cards: [DefaultCoverCard]
};

export type ProjectStatus =
  | 'Draft'
  | 'Reviewed'
  | 'Suspended'
  | 'Flagged'
  | 'Published'
  | 'Closed'
  | 'Other'
  | 'Machine Translated'
  | 'Human Translated'
  | 'Awarded'
  | 'Finished'
  | 'In Progress';

export const ProjectStatuses: ProjectStatus[] = [
  'Draft',
  'Reviewed',
  'Suspended',
  'Flagged',
  'Published',
  'Closed',
  'Other',
  'Machine Translated',
  'Human Translated',
  'Awarded',
  'Finished',
  'In Progress'
];

export type ConstructionType =
  | 'New Construction Residential'
  | 'New Construction Commercial Residential'
  | 'New Construction Commercial'
  | 'Remodel'
  | 'Retrofit'
  | 'Renovation'
  | 'Restoration'
  | 'Maintenance';

export const ConstructionTypes: ConstructionType[] = [
  'New Construction Residential',
  'New Construction Commercial Residential',
  'New Construction Commercial',
  'Remodel',
  'Retrofit',
  'Renovation',
  'Restoration',
  'Maintenance'
];

export interface ProjectState {
  userProjects: FullProject[];
  marketProjects: DisplayProject[];
  market: ConstructionType[];
  lang: SupportedLang;
}

export const LOAD_USER_PROJECTS = 'LOAD_USER_PROJECTS';
export const CLEAR_USER_PROJECTS = 'CLEAR_USER_PROJECTS';
export const ADD_PROJECT = 'ADD_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const LOAD_MARKET_PROJECTS = 'LOAD_MARKET_PROJECTS';
export const UPDATE_MARKET_TYPE = 'UPDATE_MARKET_TYPE';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';

interface LoadUserProjectAction {
  type: typeof LOAD_USER_PROJECTS;
  projects: FullProject[];
}

interface AddProjectAction {
  type: typeof ADD_PROJECT;
  project: FullProject;
}

interface UpdateProjectAction {
  type: typeof UPDATE_PROJECT;
  project: FullProject;
}

interface DeleteProjectAction {
  type: typeof DELETE_PROJECT;
  id: string;
}

interface LoadMarketProjectsAction {
  type: typeof LOAD_MARKET_PROJECTS;
  projects: DisplayProject[];
}

interface UpdateMarketTypeAction {
  type: typeof UPDATE_MARKET_TYPE;
  marketType: ConstructionType[];
  projects: DisplayProject[];
}

interface ClearUserProjectsAction {
  type: typeof CLEAR_USER_PROJECTS;
}

interface UpdateLanguageAction {
  type: typeof UPDATE_LANGUAGE;
  lang: SupportedLang;
}

export type ProjectActionTypes =
  | LoadUserProjectAction
  | AddProjectAction
  | UpdateProjectAction
  | DeleteProjectAction
  | LoadMarketProjectsAction
  | UpdateMarketTypeAction
  | ClearUserProjectsAction
  | UpdateLanguageAction;
