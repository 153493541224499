import React from 'react';
import { connect } from 'react-redux';
import Firebase from '.';
import { FullUserData } from '../../Redux/Store/User';
import { AddUser, ClearUser } from '../../Redux/Action/UserActions';
import { FullProject } from '../../Redux/Store/Project';
import {
  LoadUserProjects,
  ClearUserProjects
} from '../../Redux/Action/ProjectActions';
import { FullBid } from '../../Redux/Store/Bid';
import { LoadBids, ClearBids } from '../../Redux/Action/BidActions';

const FirebaseContext = React.createContext<Firebase>({} as Firebase);
const mapDispatchToProps = (dispatch: any) => ({
  addUser: (user: FullUserData) => dispatch(AddUser(user)),
  clearUser: () => dispatch(ClearUser()),
  loadUserProjects: (projects: FullProject[]) =>
    dispatch(LoadUserProjects(projects)),
  clearProjects: () => dispatch(ClearUserProjects()),
  loadBids: (bids: FullBid[]) => dispatch(LoadBids(bids)),
  clearBids: () => dispatch(ClearBids())
});

export interface FirebaseProps {
  addUser: (user: FullUserData) => void;
  loadUserProjects: (projects: FullProject[]) => void;
  loadBids: (bids: FullBid[]) => void;
  clearUser: () => void;
  clearProjects: () => void;
  clearBids: () => void;
}

const FirebaseContextProviderBase: React.FC<FirebaseProps> = props => {
  const fb = new Firebase(props);
  return (
    <FirebaseContext.Provider value={fb}>
      {props.children}
    </FirebaseContext.Provider>
  );
};
const FirebaseContextProvider = connect(
  null,
  mapDispatchToProps
)(FirebaseContextProviderBase);

export default FirebaseContext;
export { FirebaseContextProvider };
